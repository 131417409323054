import { Box, MenuItem, Typography, useTheme } from '@mui/material';
import React from 'react';
import { useNavigate, useOutletContext, useParams } from 'react-router-dom';

function SelecionaResumo() {
    const theme = useTheme();
    const params = useParams();
    const navigate = useNavigate();
    const { tiposResumos, setTipoResumoSelecionado } = useOutletContext();

    function selecionarTipoResumo(tipoResumo) {
        setTipoResumoSelecionado(tipoResumo);
        navigate(`/main/${params.id}/${tipoResumo.value}/gravacao`);
    }

    return (
        <Box flex={1} sx={{ overflowY: 'auto' }}>
            <Typography variant="h5" fontWeight={600} textAlign={'center'} color={theme.palette.primary.dark}>
                Selecione um tipo de resumo para continuar.
            </Typography>
            <Typography variant="body1" textAlign={'center'} color={theme.palette.text.primary} marginBottom={'10px'}>
                Seleione o tipo de resumo que indicara a nossa inteligencia artificial qual tipo de consulta que esta sendo
                gravado para que possamos gerar um resumo mais preciso.
            </Typography>
            {tiposResumos.map((tipoResumo) => (
                <MenuItem key={tipoResumo.id} value={tipoResumo} onClick={() => selecionarTipoResumo(tipoResumo)}>
                    <Box display="flex" flexDirection={'column'} width={'100%'} overflow={'hidden'}>
                        <Typography variant="body1" fontWeight={600} color={theme.palette.primary.dark}>
                            {tipoResumo.name}
                        </Typography>
                        <Typography variant="caption" color={theme.palette.text.disabled} sx={{ textWrap: 'initial' }}>
                            {tipoResumo.description}
                        </Typography>
                    </Box>
                </MenuItem>
            ))}
        </Box>
    );
}

export default SelecionaResumo;
