import dayjs from 'dayjs';
import { DBConfig, storeSchema } from '../config/IndexDBConfig';
import obterDuracaoAudio from '../helpers/getDuracaoAudio';
import { isWasmSupported } from '../helpers/verificaSuporteWebAssembly';
import { saveAudio, saveAudioSalad, saveAudioV3 } from '../services/audio';
import { FFmpeg } from '@ffmpeg/ffmpeg';
import { fetchFile, toBlobURL } from '@ffmpeg/util';
import { db } from '../config/db';

// 15mb
export const tamanhoLimite = 5 * 1024 * 1024;

export const enumStatus = {
    server: 'server',
    local: 'local',
    novo: 'novo',
    error: 'error',
};

export const statusStagiosDoProcessamento = {
    naoIniciado: 'naoIniciado',
    inciado: 'inciado',
    processando: 'processando',
    finalizado: 'finalizado',
};

export class EstagiosDoProcessamento {
    constructor(callBackChangeStatus) {
        this.criandoArquivo = statusStagiosDoProcessamento.naoIniciado;
        this.removendoRuidos = statusStagiosDoProcessamento.naoIniciado;
        this.removendoSilencios = statusStagiosDoProcessamento.naoIniciado;
        this.convertendoParaMp3 = statusStagiosDoProcessamento.naoIniciado;
        this.enviandoParaServidor = statusStagiosDoProcessamento.naoIniciado;
        this.salvandoNoIndexDB = statusStagiosDoProcessamento.naoIniciado;
        this.removendoDoIndexDB = statusStagiosDoProcessamento.naoIniciado;
        this.callBackChangeStatus = typeof callBackChangeStatus === 'function' ? callBackChangeStatus : null;
    }

    mudarEstagio(stage, status) {
        if (typeof status !== 'string' || !Object.values(statusStagiosDoProcessamento).includes(status))
            throw new Error('Invalid type for status or name, expected string and status must be server, local or novo');

        if (!Object.keys(this).includes(stage)) throw new Error('Invalid stage, expected one of the stages');

        if (this[stage] !== status) {
            this[stage] = status;
            if (this.callBackChangeStatus) this.callBackChangeStatus({ stage, status });
        }
    }
}

export class optionsAudioR1 {
    constructor({
        logFfmpeg = false,
        logFfmpegCallback = null,
        callbackChangeStatus = null,
        callbackFfmpegProgress = null,
        callbackChangeEstagio = null,
    }) {
        this.logFfmpeg = logFfmpeg;
        this.logFfmpegCallback = logFfmpegCallback;
        this.callbackChangeStatus = callbackChangeStatus;
        this.callbackChangeEstagio = callbackChangeEstagio;
        this.callbackFfmpegProgress = callbackFfmpegProgress;
    }
}

export class AudioR1 {
    _processando = false;
    _ffmpeg = new FFmpeg();
    _convertido = false;
    _typeFile = 'audio/mp3';
    _estagios = null;
    _options = new optionsAudioR1({
        logFfmpeg: false,
        logFfmpegCallback: null,
        callbackChangeStatus: null,
        callbackFfmpegProgress: null,
        callbackChangeEstagio: null,
    });

    constructor({ name, atendimentoId, date, blob, status, options }) {
        if (!name || !atendimentoId || !date || !blob) {
            throw new Error('AudioR1 deve ser instanciado com name, atendimentoId, date e blob');
        }
        if (typeof name !== 'string') {
            throw new Error('Invalid type for name, expected string');
        }
        if (typeof atendimentoId !== 'string') {
            throw new Error('Invalid type for atendimentoId, expected string');
        }
        if (!(date instanceof Date)) {
            throw new Error('Invalid type for date, expected Date');
        }
        if (!(blob instanceof Blob)) {
            throw new Error('Invalid type for blob, expected Blob');
        }
        if (typeof options !== 'undefined' && options instanceof optionsAudioR1) {
            this._options = options;
        }

        if (typeof status !== 'string' || !Object.values(enumStatus).includes(status))
            throw new Error('Invalid type for status or name, expected string and status must be server, local or novo');

        this._estagios =
            typeof this._options.callbackChangeEstagio === 'function'
                ? new EstagiosDoProcessamento(this._options.callbackChangeEstagio)
                : new EstagiosDoProcessamento();

        this._estagios.mudarEstagio('criandoArquivo', statusStagiosDoProcessamento.inciado);

        this._name = name.replace('.mp3', '');
        this._atendimentoId = atendimentoId;
        this._date = date;
        this._blob = blob;
        // this.verificaTamanhoArquivo(); verificar depois a melhor forma de fazer isso no front.
        this.changeStatus(status);

        this._processaAudio();
    }

    gerarAudioURL() {
        return URL.createObjectURL(this._blob, { type: 'audio/mp3' });
    }

    async _processaAudio() {
        this._estagios.mudarEstagio('criandoArquivo', statusStagiosDoProcessamento.processando);
        if (this._processando) return;
        console.log('processando audio');
        this._processando = true;
        if (isWasmSupported()) {
            console.log('Arquivo antes do processamento: ' + this.gerarAudioURL());
            await this._ajustaArquivoBruto(this._ffmpeg);
            console.log('Arquivo depois do processamento: ' + this.gerarAudioURL());
        }

        this._duracao = await obterDuracaoAudio(this._blob);

        if (this._status === enumStatus.local || this._status === enumStatus.novo) {
            const responseUpload = await this._uploadAudioServidor();

            if (this._status === enumStatus.local && responseUpload) await this._removeAudioIndexDB();

            if (responseUpload) this.changeStatus(enumStatus.server);

            if (this._status === enumStatus.novo) {
                const responseSalvaIndexDB = await this._addAudioIndexDB();
                if (responseSalvaIndexDB) {
                    this.changeStatus(enumStatus.local);
                }
            }
        }

        if (this._status !== enumStatus.server) {
            return this._processaAudio();
        } else {
            this._estagios.mudarEstagio('criandoArquivo', statusStagiosDoProcessamento.finalizado);
            this._ffmpeg = null;
        }
    }

    async _addAudioIndexDB() {
        try {
            this._estagios.mudarEstagio('salvandoNoIndexDB', statusStagiosDoProcessamento.inciado);
            console.log('Salvando audio no IndexDB');
            const audio = {
                name: `${this._name}.mp3`,
                atendimentoId: this._atendimentoId,
                date: this._date,
                blob: this._blob,
                status: enumStatus.local,
            };
            this._estagios.mudarEstagio('salvandoNoIndexDB', statusStagiosDoProcessamento.processando);
            const id = await db.audios.add(audio);
            this._estagios.mudarEstagio('salvandoNoIndexDB', statusStagiosDoProcessamento.finalizado);
            return id;
        } catch (e) {
            console.log('Erro ao salvar audio no IndexDB');
            console.error(e);
            return false;
        }
    }

    async _removeAudioIndexDB() {
        try {
            this._estagios.mudarEstagio('removendoDoIndexDB', statusStagiosDoProcessamento.inciado);
            console.log('Removendo audio do IndexDB');
            this._estagios.mudarEstagio('removendoDoIndexDB', statusStagiosDoProcessamento.processando);
            await db.audios.delete(`${this._name}.mp3`);
            this._estagios.mudarEstagio('removendoDoIndexDB', statusStagiosDoProcessamento.finalizado);
            return true;
        } catch (e) {
            console.log('Erro ao remover audio do IndexDB');
            console.error(e);
            return false;
        }
    }

    async _getAudioByNameIndexedDB() {
        try {
            console.log('Buscando audio no IndexDB');
            const audio = await db.audios.get(`${this._name}.mp3`);
            return audio;
        } catch (e) {
            console.log('Erro ao buscar audio no IndexDB');
            console.error(e);
            return false;
        }
    }

    async _uploadAudioServidor() {
        try {
            console.log('Salvando audio no servidor');
            this._estagios.mudarEstagio('enviandoParaServidor', statusStagiosDoProcessamento.inciado);
            this._estagios.mudarEstagio('enviandoParaServidor', statusStagiosDoProcessamento.processando);
            var responseFetch = await saveAudioV3(
                this._blob,
                `${this._name}.mp3`,
                this._atendimentoId,
                dayjs(this._date).format('YYYY-MM-DD HH:mm'),
                this._convertido,
            );
            if (responseFetch.status === 200) {
                this.notificaAudioSalvoParaOutraAbaIframe();
                this._estagios.mudarEstagio('enviandoParaServidor', statusStagiosDoProcessamento.finalizado);
                return true;
            }
            return false;
        } catch (e) {
            console.log('Erro ao salvar audio no servidor');
            console.error(e);
            return false;
        }
    }

    async _ajustaArquivoBruto(ffmpeg) {
        try {
            this._estagios.mudarEstagio('removendoRuidos', statusStagiosDoProcessamento.inciado);
            this._estagios.mudarEstagio('removendoSilencios', statusStagiosDoProcessamento.inciado);
            this._estagios.mudarEstagio('convertendoParaMp3', statusStagiosDoProcessamento.inciado);

            // const baseURL = 'https://unpkg.com/@ffmpeg/core@0.12.6/dist/umd';
            // await ffmpeg.load({
            //     coreURL: await toBlobURL(`${baseURL}/ffmpeg-core.js`, 'text/javascript'),
            //     wasmURL: await toBlobURL(`${baseURL}/ffmpeg-core.wasm`, 'application/wasm'),
            // });
            ffmpeg.on('log', ({ message }) => {
                if (this._options.logFfmpeg) console.log(message);
            });
            ffmpeg.on('progress', (params) => {
                if (this._options.callbackFfmpegProgress) this._options.callbackFfmpegProgress(params);
            });
            await ffmpeg.load();

            // 1. Carrega o arquivo de entrada no sistema de arquivos do FFmpeg
            await ffmpeg.writeFile(`${this._name}.mp3`, await fetchFile(URL.createObjectURL(this._blob, { type: 'audio/mp3' })));

            // 2. Remove ruídos usando o filtro `afftdn`
            this._estagios.mudarEstagio('removendoRuidos', statusStagiosDoProcessamento.processando);
            await ffmpeg.exec([
                '-i',
                `${this._name}.mp3`,
                '-af',
                'afftdn=nf=-25', // Ajuste `nf` para controlar a sensibilidade
                `${this._name}_no_noise.mp3`,
            ]);
            this._estagios.mudarEstagio('removendoRuidos', statusStagiosDoProcessamento.finalizado);

            // 3. Remove silêncios usando o filtro `silenceremove`
            this._estagios.mudarEstagio('removendoSilencios', statusStagiosDoProcessamento.processando);

            // stop_periods é o tempo em segundos que o silêncio deve durar para ser removido,
            // stop_duration é a duração do silêncio em segundos
            // stop_threshold é o nível de silêncio em dB
            await ffmpeg.exec([
                '-i',
                `${this._name}_no_noise.mp3`,
                '-af',
                'silenceremove=stop_periods=-1:stop_duration=2:stop_threshold=-60dB',
                `${this._name}_no_silence.mp3`,
            ]);
            this._estagios.mudarEstagio('removendoSilencios', statusStagiosDoProcessamento.finalizado);

            // 4. Converte o áudio final para MP3
            this._estagios.mudarEstagio('convertendoParaMp3', statusStagiosDoProcessamento.processando);
            await ffmpeg.exec([
                '-i',
                `${this._name}_no_silence.mp3`,
                '-b:a',
                '192k', // Define a taxa de bits do MP3
                `${this._name}_output.mp3`,
            ]);
            this._estagios.mudarEstagio('convertendoParaMp3', statusStagiosDoProcessamento.finalizado);

            const data = await ffmpeg.readFile(`${this._name}_output.mp3`);
            this._blob = new Blob([data.buffer], { type: 'audio/mp3' });
            this._convertido = true;
        } catch (e) {
            console.log('Erro ao ajustar arquivo bruto');
            console.error(e);
        }
    }

    notificaAudioSalvoParaOutraAbaIframe() {
        try {
            window.parent.postMessage(
                {
                    tipo: 'audio',
                    blob: this._blob,
                    name: `${this._name}.mp3`,
                    atendimentoId: this._atendimentoId,
                    final: false,
                },
                '*',
            );
        } catch (e) {
            console.log('Erro ao enviar audio para o parent');
            console.error(e);
        }
    }

    changeStatus(status) {
        if (this._status === status) return;
        if (typeof status !== 'string' || !Object.values(enumStatus).includes(status))
            throw new Error('Invalid type for status or name, expected string and status must be server, local or novo');
        this._status = status;
        if (this._options.callbackChangeStatus) this._options.callbackChangeStatus(this._status);
    }

    verificaTamanhoArquivo() {
        debugger;
        if (this._blob.size > tamanhoLimite) {
            console.log('Arquivo muito grande', this._blob.size);
            this.changeStatus(enumStatus.error);
            throw new Error('Arquivo muito grande, por favor selecione um arquivo menor');
        }
    }

    get name() {
        return this._name;
    }

    get atendimentoId() {
        return this._atendimentoId;
    }

    get status() {
        return this._status;
    }

    get date() {
        return this._date;
    }

    get blob() {
        return this._blob;
    }

    get duracao() {
        return this._duracao;
    }
}

export function geraNomeArquivo(blobFile) {
    if (!(blobFile instanceof Blob)) {
        throw new Error('Invalid type for blob, expected Blob');
    }

    const blobUrl = URL.createObjectURL(blobFile);
    let nome = blobUrl.replace(`blob:${window.location.origin}/`, '');
    nome = `${nome}.${blobFile.type.split('/')[1]}`;
    return nome;
}
