import dayjs from 'dayjs';
import { toast } from 'react-toastify';
import { saveAudio } from '../services/audio';
import { db } from './db';

export const storeSchema = {
    name: 'audios',
    keyPath: 'id',
    autoIncrement: true,
    indexes: [
        {
            name: 'name',
            keyPath: 'name',
            options: { unique: true },
        },
        {
            name: 'atendimentoId',
            keyPath: 'atendimentoId',
            options: { unique: false },
        },
        {
            name: 'status',
            keyPath: 'status',
            options: { unique: false },
        },
        {
            name: 'date',
            keyPath: 'date',
            options: { unique: false },
        },
        {
            name: 'blob',
            keyPath: 'blob',
            options: { unique: false },
        },
        {
            name: 'duracao',
            keyPath: 'duracao',
            options: { unique: false },
        },
    ],
};

export const DBConfig = {
    name: 'R1-LOCAL-DB',
    version: 3,
    objectStoresMeta: [
        {
            store: storeSchema.name,
            storeConfig: { keyPath: storeSchema.keyPath, autoIncrement: storeSchema.autoIncrement },
            storeSchema: storeSchema.indexes,
        },
    ],
};

export const getAudiosByAtendimentoId = async (atendimentoId) => {
    try {
        const audios = await db.audios.where('atendimentoId').equals(atendimentoId).toArray();
        return audios;
    } catch (error) {
        console.error(error);
        return [];
    }
};

const corGerandoBlob = '#030637';
const corEnvioServidor = '#8EAC50';
const corSalvaIndexedDB = '#D3D04F';
export class AudioR1 {
    constructor(name, atendimentoId, status, date, blob, duracao) {
        this._name = name;
        this._atendimentoId = atendimentoId;
        this._status = status;
        this._date = date;
        this._blob = blob;
        this._duracao = duracao;
    }

    async enviaServidor() {
        try {
            console.log(
                `%c ☁️ [Callback salva audio]  Ininciando a gravação AudioR1, gravando no [servidor].`,
                `color:${corEnvioServidor}`,
            );

            const resultSaveAudio = await saveAudio(
                this._blob,
                this._name,
                this._atendimentoId,
                dayjs(this._date).format('YYYY-MM-DD HH:mm'),
            );

            const objAudio = {
                tipo: 'audio',
                blob: this._blob,
                name: this._name,
                atendimentoId: this._atendimentoId,
                final: false,
            };
            window.parent.postMessage(objAudio, '*');
            if (resultSaveAudio.status == 200) {
                return true;
            }

            return false;
        } catch (error) {
            console.log(`%c ☁️ [Callback salva auido] Erro ao gravar AudioR1 no [servidor].`, error);
            toast.error('Erro ao salvar no servidor audio.');
            if (error?.response?.data?.errors.length) {
                error?.response?.data?.errors.forEach.call(error.response.data.errors, (erro) => {
                    toast.error(erro);
                });
                return false;
            }
        }
    }

    async salvaIndexedDB(enviadoParaServer) {
        try {
            console.log(
                `%c 🖥️ [Callback salva audio] Ininciando a gravação AudioR1, gravando no [indexDB].`,
                `color:${corSalvaIndexedDB}`,
            );
            const id = await db.audios.add({
                name: this._name,
                atendimentoId: this._atendimentoId,
                status: enviadoParaServer ? 'server' : 'local',
                date: this._date,
                blob: this._blob,
                duracao: this._duracao,
            });
            console.log(`%c 🖥️ [Callback salva audio] AudioR1 [${id}] gravado no [indexDB].`, `color:${corSalvaIndexedDB}`);
            return true;
        } catch (error) {
            console.log(`%c 🖥️ [Callback salva audio] Erro ao gravar AudioR1 no [indexDB].`, `color:${corSalvaIndexedDB}`);
            console.error(error);
            return false;
        }
    }

    get name() {
        return this._name;
    }

    set name(value) {
        if (typeof value !== 'string') {
            throw new Error('Invalid type for name, expected string');
        }
        this._name = value;
    }

    get atendimentoId() {
        return this._atendimentoId;
    }

    set atendimentoId(value) {
        if (typeof value !== 'string') {
            // Assuming GUID is a string
            throw new Error('Invalid type for atendimentoId, expected string');
        }
        this._atendimentoId = value;
    }

    get status() {
        return this._status;
    }

    set status(value) {
        // Assuming StatusAudio is an object, replace with appropriate check if not
        if (typeof value !== 'object') {
            throw new Error('Invalid type for status, expected object');
        }
        this._status = value;
    }

    get date() {
        return this._date;
    }

    set date(value) {
        if (!(value instanceof Date)) {
            throw new Error('Invalid type for date, expected Date');
        }
        this._date = value;
    }

    get blob() {
        return this._blob;
    }

    set blob(value) {
        if (!(value instanceof Blob)) {
            throw new Error('Invalid type for blob, expected Blob');
        }
        this._blob = value;
    }

    url() {
        return URL.createObjectURL(this._blob);
    }

    get duracao() {
        return this._duracao;
    }

    set duracao(value) {
        if (typeof value !== 'number') {
            throw new Error('Invalid type for duracao, expected number');
        }
        this._duracao = value;
    }
}
