import React, { useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate, useOutletContext, useParams } from 'react-router-dom';
import AudioControles from '../AudioControles/AudioControles';
import { Box, FormControl, InputLabel, MenuItem, Select, Typography, useTheme } from '@mui/material';
import { ContainerAudioCapture } from './Gravacao.stytles';
import MicIcon from '@mui/icons-material/Mic';

function Gravacao() {
    const theme = useTheme();
    const params = useParams();
    const navigate = useNavigate();
    const {
        atendimento,
        tiposResumos,
        gravando,
        setGravando,
        streamMic,
        setStreamMic,
        micSelecionado,
        setMicSelecionado,
        refreshAtendimento,
    } = useOutletContext();

    const tipoResumoSelecionado = useMemo(() => {
        const resumoSelecionado = params.resumoType;
        return tiposResumos.find((tipo) => tipo.value === resumoSelecionado);
    }, [tiposResumos, params]);

    const roteiroAtendimentoSelecionado = useMemo(() => {
        if (!tipoResumoSelecionado && !tipoResumoSelecionado?.parametrosJSON) return [];

        return JSON.parse(tipoResumoSelecionado.parametrosJSON);
    }, [tipoResumoSelecionado]);

    return (
        <ContainerAudioCapture style={{ borderColor: theme.palette.primary.main }}>
            <FormControl focused fullWidth>
                <InputLabel id="demo-multiple-chip-label">Tipo de Resumo</InputLabel>
                {tipoResumoSelecionado && (
                    <Select
                        id="standard-select-currency"
                        label="Tipo de Resumo"
                        helperText="Selecione o tipo de resumo que deseja que seja gerado."
                        variant="outlined"
                        value={tipoResumoSelecionado}
                        renderValue={(selected) => (
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexWrap: 'wrap',
                                    gap: 0.5,
                                    borderRadius: '4px',
                                }}
                            >
                                <Typography
                                    variant="body2"
                                    textAlign={'center'}
                                    fontWeight={600}
                                    textTransform={'uppercase'}
                                    width={'100%'}
                                >
                                    {selected.name}
                                </Typography>
                            </Box>
                        )}
                    >
                        {tiposResumos.map((tipoResumo) => (
                            <MenuItem
                                key={tipoResumo.id}
                                value={tipoResumo}
                                onClick={() => navigate(`/main/${params.id}/${tipoResumo.value}/gravacao`)}
                            >
                                <Box display="flex" flexDirection={'column'} width={'100%'} overflow={'hidden'}>
                                    <Typography variant="body2" fontWeight={600}>
                                        {tipoResumo.name}
                                    </Typography>
                                    <Typography
                                        variant="caption"
                                        color={theme.palette.text.disabled}
                                        sx={{ textWrap: 'initial' }}
                                    >
                                        {tipoResumo.description}
                                    </Typography>
                                </Box>
                            </MenuItem>
                        ))}
                    </Select>
                )}
            </FormControl>
            <Box flex={1} marginTop={'10px'} display={'flex'} flexDirection={'column'} overflow={'auto'} width={'100%'}>
                <Box display={'flex'} marginBottom={'10px'} justifyContent={gravando ? 'space-between' : 'center'}>
                    <Typography
                        variant="h5"
                        fontWeight={600}
                        textAlign={gravando ? 'center' : 'left'}
                        color={theme.palette.primary.dark}
                    >
                        Roteiro do Atendimento
                    </Typography>
                    {gravando && (
                        <Box
                            padding={'5px'}
                            display={'flex'}
                            justifyContent={'center'}
                            alignItems={'center'}
                            position={'absolute'}
                            right={'10px'}
                        >
                            <TextoGravando qtdPalavasComputadas={atendimento.palavasComputadas} />
                            <Box
                                marginLeft={'10px'}
                                display={'flex'}
                                alignItems={'center'}
                                justifyContent={'center'}
                                height={'28px'}
                                width={'28px'}
                                borderRadius={'50%'}
                                bgcolor={'red'}
                                boxShadow={'0px 0px 4px 2px red'}
                                className="pulse-red"
                            >
                                <MicIcon sx={{ color: '#fff', fontSize: 20 }} />
                            </Box>
                        </Box>
                    )}
                </Box>
                <Box
                    display="flex"
                    flexDirection={'column'}
                    width={'100%'}
                    height={'100%'}
                    overflow={'auto'}
                    paddingBottom={'63px'}
                    marginBottom={'5px'}
                >
                    {roteiroAtendimentoSelecionado.map((item) => (
                        <Box padding={'5px'} key={item.titlo}>
                            <Typography variant="body1" fontWeight={600} color={theme.palette.primary.dark}>
                                {item.titulo}
                            </Typography>
                            <Typography variant="caption" color={theme.palette.text.disabled}>
                                {item.descricao}
                            </Typography>
                        </Box>
                    ))}
                </Box>
            </Box>
            <AudioControles
                audiosJaGerados={atendimento?.audios ? atendimento.audios : []}
                gravando={gravando}
                setGravando={setGravando}
                streamMic={streamMic}
                updateStream={setStreamMic}
                idAtendimento={params.id}
                micSelecionado={micSelecionado}
                setMicSelecionado={setMicSelecionado}
                refreshAtendimento={refreshAtendimento}
            />
        </ContainerAudioCapture>
    );
}

export default Gravacao;

function TextoGravando({ qtdPalavasComputadas }) {
    const theme = useTheme();
    const [mostraPalavras, setMostraPalavras] = useState(false);
    useEffect(() => {
        const timer = setInterval(() => {
            setMostraPalavras((prevState) => !prevState);
        }, 5000);
        return () => clearInterval(timer);
    }, []);

    if (!mostraPalavras)
        return (
            <Typography
                fontSize={'16px'}
                variant="caption"
                color={theme.palette.success.main}
                fontWeight={600}
                marginLeft={'5px'}
            >
                {qtdPalavasComputadas} palavras computadas
            </Typography>
        );

    return (
        <Typography fontSize={'16px'} variant="caption" color={'red'} fontWeight={600} marginLeft={'5px'}>
            Escutando a consulta...
        </Typography>
    );
}
