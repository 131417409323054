import React, { useEffect, useMemo, useState } from 'react';

import { Container, ContainerAssistentes, AssistenteStyle } from './GerandoResumo.styles';
import { useNavigate, useOutletContext, useParams } from 'react-router-dom';
import { Backdrop, CircularProgress, Typography, Box, Button } from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import { toast } from 'react-toastify';
import { geraResumoAssistenteEspecialistaStream, salvaResumoAsync } from '../../../services/resumo';
import { getAtendimentoById } from '../../../services/atendimento';

function GerandoResumo() {
    const params = useParams();
    const navigate = useNavigate();

    const [loadingResumo, setLoadingResumo] = useState(false);
    const [resumosJaFinalizados, setResumosJaFinalizados] = useState([]);

    const { atendimento, tiposResumos, setAtendimento } = useOutletContext();

    const tipoResumo = useMemo(() => tiposResumos.find((tipo) => tipo.value === params.resumoType), [tiposResumos, params]);
    const assistentes = tipoResumo?.assistentesPrompts || [];

    const isFinish = useMemo(() => {
        return resumosJaFinalizados.length >= assistentes.length && assistentes.length > 0;
    }, [resumosJaFinalizados, assistentes]);

    async function salvaResumoNoAtendimento(atendimento, params) {
        try {
            setLoadingResumo(true);
            const retorno = await salvaResumoAsync(atendimento.id, params.resumoType);
            closeResumoStreamDiv(retorno.texto);
            console.log('%c Log retorno gerado', retorno, 'color: green');
            toast.success('Resumo finalizado', { position: 'bottom-center' });
            setTimeout(() => {
                navigate(`/main/${params.id}/resumoViewer/${retorno.id}`);
            }, 1000);
        } catch (error) {
            console.error(error);
        } finally {
            setLoadingResumo(false);
        }
    }

    useEffect(() => {
        if (isFinish) {
            salvaResumoNoAtendimento(atendimento, params);
        }
    }, [isFinish, atendimento, params]);

    async function closeResumoStreamDiv(textoMontado) {
        const objectResumo = {
            tipo: 'resumo',
            idAtendimento: atendimento.id,
            tipoResumo: tipoResumo?.value,
            texto: textoMontado,
            final: true,
        };
        window.parent.postMessage(objectResumo, '*');
    }

    return (
        <Container>
            <Typography
                variant="h6"
                component="h1"
                textTransform={'uppercase'}
                fontWeight={600}
                gutterBottom
                textAlign={'center'}
                fontSize={'1rem'}
            >
                {tipoResumo?.name}
            </Typography>
            {tipoResumo && (
                <ContainerAssistentes>
                    {assistentes.map((assistente) => (
                        <Assistente
                            key={assistente.id}
                            assistente={assistente}
                            updateListaFinalizados={setResumosJaFinalizados}
                            atendimentoId={atendimento.id}
                        />
                    ))}
                </ContainerAssistentes>
            )}
            <Backdrop
                sx={(theme) => ({ color: '#fff', zIndex: theme.zIndex.drawer + 1 })}
                open={loadingResumo || tipoResumo == undefined}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </Container>
    );
}

export default GerandoResumo;

const Assistente = ({ assistente, updateListaFinalizados, atendimentoId }) => {
    const [texto, setTexto] = useState('');
    const ref = React.useRef();

    useEffect(() => {
        init(assistente);
    }, [assistente]);

    function resizeTextarea(target) {
        target.style.height = 'auto';
        target.style.height = target.scrollHeight + 'px';
    }

    useEffect(() => {
        if (ref.current) resizeTextarea(ref.current);
    }, [texto]);

    function init(assistente) {
        function calllback(isFinish, texto) {
            if (isFinish) return updateListaFinalizados((prev) => [...prev, assistente.id]);
            setTexto((prev) => prev + texto);
        }

        function errorFn(error) {
            console.error(error);
            toast.error(`Error ao tentar gerar resumo do assistente: "${assistente.titulo}"`);

            setTexto(
                (prev) => `${prev} <p style="color:red">Erro ao tentar gerar resumo do assistente: "${assistente.titulo}"</p>`,
            );
        }

        geraResumoAssistenteEspecialistaStream(
            {
                idAtendimento: atendimentoId,
                idAssistentePrompt: assistente.id,
            },
            errorFn,
            calllback,
        );
    }

    return (
        <AssistenteStyle>
            <Box display="flex" justifyContent="space-between" alignItems="center" width="100%" marginTop="10px">
                <h3>{assistente.titulo}</h3>
                <Button
                    startIcon={<RefreshIcon />}
                    variant="outlined"
                    size="small"
                    onClick={() => {
                        setTexto('');
                        init(assistente);
                    }}
                >
                    Gerar novamente
                </Button>
            </Box>
            {texto.length == 0 ? (
                <article>Processando, aguarde...</article>
            ) : (
                <div className="div-textarea-resumo">
                    <textarea
                        ref={ref}
                        onChange={(e) => resizeTextarea(e.target)}
                        value={texto.replaceAll('%%%', '\n')}
                        onFocus={(e) => resizeTextarea(e.target)}
                    ></textarea>
                </div>
            )}
        </AssistenteStyle>
    );
};
