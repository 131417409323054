import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow-y: hidden;
    overflow-x: hidden;
    height: 100%;
`;

export const AssistenteStyle = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    font-family: 'Roboto', sans-serif;
    max-width: 800px;
    margin: 0 auto;

    .containerTituloBloco {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        padding: 0px 10px;
        margin-top: 20px;
    }

    > .containerTituloBloco > h3 {
        margin: 0px;
        font-size: 20px;
        font-style: italic;
        text-transform: capitalize;
    }

    > .containerTextArea {
        flex: 1;
        display: flex;
        overflow-x: hidden;
        width: 100%;
        padding: 10px;
    }

    > .containerTextArea > div {
        font-family: 'Roboto', sans-serif;
        width: 100%;
        background-color: transparent;
        border: none;
        color: #000;
        resize: none;

        line-height: 1.5;
        font-weight: 400;
        font-size: 16px;
        word-spacing: 1px;
        letter-spacing: 0.5px;
        text-shadow: 1px 1px 2px rgba(255, 255, 255, 0.1);
        background-color: #f5f5f5;
        padding: 10px;
        border-radius: 5px;
        white-space: break-spaces;
        &:focus-visible {
            outline: none;
        }
    }
`;
