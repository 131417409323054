import React, { useEffect, useMemo, useState } from 'react';
import TextField from '@mui/material/TextField';
import { useTheme } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { Outlet, useOutletContext, useParams, useSearchParams } from 'react-router-dom';
import InputAdornment from '@mui/material/InputAdornment';
import EditIcon from '@mui/icons-material/Edit';

import { Container, ContainerAudioCapture, ContainerInfos } from './Atendimento.styles';
import { getAtendimentoById, updateAtendimento } from '../../../services/atendimento';
import { getTiposResumos } from '../../../services/resumo';
import { useMain } from '../../../contexts/MainContext';

import { GetStream, SolicitaPermissaoMicrofone } from '../../../helpers/Mic';
import ErroAudio from '../../erro/ErroAudio';
import SelectMicDialog from '../Components/SelectMicDialog/SelectMicDialog';

function Atendimento() {
    const theme = useTheme();
    const param = useParams();
    const { setGlobalIsRecording, globalIsRecording } = useMain();
    const [isExternal] = useOutletContext();
    const [loadAtendimento, setLoadAtendimento] = useState(false);
    const [atendimento, setAtendimento] = useState({});
    const [tiposResumos, setTiposResumos] = useState([]);
    const [isOpenConfigAtendimento, setOpenConfigAtendimento] = useState(false);
    const [tipoResumoSelecionado, setTipoResumoSelecionado] = useState(null);
    const [micSelecionado, setMicSelecionado] = useState(null);
    const [streamMic, setStreamMic] = useState(null);
    const [pageError, setPageError] = useState(null);

    useEffect(() => {
        if (streamMic) {
            streamMic.getAudioTracks().forEach((track) => {
                track.onended = () => {
                    console.log(`Compartilhamento da track "${track.kind}" foi interrompido pelo usuário.`);
                    setGlobalIsRecording(false);
                    SolicitaPermissaoMicrofone()
                        .then((dispositivos) => {
                            createAndSetStreamMic(micSelecionado.deviceId);
                        })
                        .catch((err) => {
                            console.error(err);
                            setStreamMic(null);
                        });
                };
            });
        }
    }, [streamMic]);

    useEffect(() => {
        init(param.id);
    }, [param.id]);

    useEffect(() => {
        if (!micSelecionado) return;
        createAndSetStreamMic(micSelecionado.deviceId);
    }, [micSelecionado]);

    useEffect(() => {
        if (!globalIsRecording && streamMic) {
            streamMic.getAudioTracks().forEach((track) => {
                track.stop();
            });
        }
    }, [globalIsRecording, streamMic]);

    async function init(idAtendimento) {
        try {
            setLoadAtendimento(true);
            const [atendimento, tiposResumo] = await Promise.all([getAtendimentoById(idAtendimento), getTiposResumos()]);
            setAtendimento(atendimento);
            setTiposResumos(tiposResumo);
            const dispositivos = await SolicitaPermissaoMicrofone();
            if (dispositivos.length === 0) throw new Error('Nenhum microfone encontrado');
            createAndSetStreamMic(dispositivos[0].deviceId);
            setMicSelecionado(dispositivos[0]);
        } catch (err) {
            console.error(err);
            if (err?.message) setPageError(err);
        } finally {
            setLoadAtendimento(false);
        }
    }

    async function refreshAtendimento(idAtendimento) {
        try {
            console.log(`Atualizando atendimento ${idAtendimento}...`);
            const atendimentoAtualizado = await getAtendimentoById(idAtendimento);
            setAtendimento(atendimentoAtualizado);
        } catch (err) {
            console.error(err);
        }
    }

    async function createAndSetStreamMic(deviceId) {
        const optionsMidiaDevice = {
            audio: { deviceId: { exact: deviceId } },
            video: false,
        };
        const stream = await GetStream(optionsMidiaDevice);
        return setStreamMic(stream);
    }

    if (pageError) return <ErroAudio erro={pageError} />;

    return (
        <>
            <Container>
                <div className="ContainerBackgrond">
                    <ContainerInfos>
                        <TextField
                            size="small"
                            variant="outlined"
                            label="Nome consulta"
                            onKeyDown={(e) => e.key === 'Enter' && e.target.blur()}
                            // onBlur={atualizaAtendimento}
                            onChange={(e) => setAtendimento({ ...atendimento, nome: e.target.value })}
                            value={atendimento?.nome}
                            defaultValue={atendimento?.nome}
                            focused
                            placeholder="Nome do atendimento"
                            id="nomeAtendimento"
                            type={'text'}
                            title="Clique para editar o nome do atendimento"
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            edge="end"
                                            onClick={() => setOpenConfigAtendimento(!isOpenConfigAtendimento)}
                                        >
                                            <EditIcon />
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </ContainerInfos>
                    <Outlet
                        context={{
                            atendimento,
                            setAtendimento,
                            tipoResumoSelecionado,
                            setTipoResumoSelecionado,
                            tiposResumos,
                            setTiposResumos,
                            gravando: globalIsRecording,
                            setGravando: setGlobalIsRecording,
                            streamMic,
                            setStreamMic,
                            micSelecionado,
                            setMicSelecionado,
                            createAndSetStreamMic,
                            refreshAtendimento,
                        }}
                    />
                </div>
            </Container>
            ;
        </>
    );
}

export default Atendimento;
