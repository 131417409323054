export default function converterSegundosParaMinutos(segundosparams, formato = 'completo') {
    try {
        if (segundosparams == 0) {
            return `0 min e 0 seg`;
        }
        if (segundosparams > 60) {
            const minutos = Math.floor(segundosparams / 60);
            const segundos = Math.floor(segundosparams % 60);
            if (minutos > 1) {
                if (formato == 'reduzido') {
                    return `${minutos}min e ${segundos}seg`;
                }
                return `${minutos} Minutos e ${segundos} segundos`;
            } else {
                if (formato == 'reduzido') {
                    return `${minutos}min e ${segundos}seg`;
                }
                return `${minutos} Minuto e ${segundos} Segundos`;
            }
        } else {
            let segundos = Math.floor(segundosparams);
            if (segundos > 0) {
                if (formato == 'reduzido') {
                    return `0 min e ${segundos}seg`;
                }
                return `0 Minutos e ${segundos} Segundo`;
            } else {
                if (formato == 'reduzido') {
                    return `${segundos}seg`;
                }
                return `0 Minutos e ${segundos} Segundos`;
            }
        }
    } catch (error) {
        console.error(error);
        return 'Error';
    }
}
