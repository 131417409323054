export const isWasmSupported = async () => {
    try {
        const module = new Uint8Array([
            0x00,
            0x61,
            0x73,
            0x6d, // Magic number
            0x01,
            0x00,
            0x00,
            0x00, // WASM binary format version
        ]);
        await WebAssembly.instantiate(module);
        console.log('WebAssembly é suportado!');
        return true;
    } catch (e) {
        console.log('WebAssembly não é suportado:', e.message);
        return false;
    }
};
