import React from 'react';
import { Backdrop, Box, Button, CircularProgress, Dialog, DialogContent, TextField, Typography } from '@mui/material';
import { CloseIcon, TitleSimulacao } from '../../pages/apresentacao/apresentacao.style';
import { enviaContato } from '../../services/contato';
import { toast } from 'react-toastify';

function ContatoCompra({ close, isOpen }) {
    const [loading, setLoading] = React.useState(false);
    const [contatoInfo, setContatoInfo] = React.useState({
        Nome: '',
        Celular: '',
        Mensagem: 'Olá gostaria de mais informações sobre o produto.',
    });

    async function EnviarContato(event) {
        setLoading(true);
        try {
            event.preventDefault();
            const { Nome, Celular, Mensagem } = contatoInfo;
            await enviaContato(Nome, Celular, Mensagem);
            toast.success('Mensagem enviado com sucesso');
            close();
            setContatoInfo({
                Nome: '',
                Celular: '',
                Mensagem: '',
            });
        } catch (error) {
            console.error(error);
            toast.error('Erro ao enviar email.');
        } finally {
            setLoading(false);
        }
    }

    return (
        <>
            <Dialog onClose={close} open={isOpen} PaperProps={{ sx: { maxWidth: 'unset' } }}>
                <Box position={'relative'} p={2} bgcolor={'primary.main'} color={'white'}>
                    <Box>
                        <Typography variant="body1" textAlign={'center'} fontSize={24}>
                            Contato Comercial
                        </Typography>
                        <Typography variant="body2" textAlign={'center'} fontSize={16} fontWeight={400}>
                            Preencha os campos abaixo para um de nossos consultores entrar em contato.
                        </Typography>
                    </Box>
                    <Box position={'absolute'} right={0} top={-5}>
                        <CloseIcon onClick={close} style={{ color: '#fff' }} />
                    </Box>
                </Box>
                <DialogContent>
                    <Box
                        component={'form'}
                        p={2}
                        display={'flex'}
                        flexDirection={'column'}
                        sx={{ minWidth: 500 }}
                        onSubmit={(event) => EnviarContato(event)}
                    >
                        <TextField
                            value={contatoInfo.Nome}
                            onChange={({ target }) => setContatoInfo({ ...contatoInfo, Nome: target.value })}
                            sx={{ mb: 3 }}
                            variant="outlined"
                            size="small"
                            focused
                            label={'Nome'}
                            placeholder="Informe seu nome."
                            required
                        />
                        <TextField
                            sx={{ mb: 3 }}
                            value={contatoInfo.Celular}
                            onChange={({ target }) => setContatoInfo({ ...contatoInfo, Celular: target.value })}
                            variant="outlined"
                            size="small"
                            focused
                            label={'Telefone'}
                            placeholder="Informe um telefone para contato. ex:21988888888 "
                            required
                            title="ex: 21988887777"
                            inputProps={{
                                maxLength: 20,
                                'x-autocompletetype': 'tel',
                                pattern: '^((1[1-9])|([2-9][0-9]))((3[0-9]{3}[0-9]{4})|(9[0-9]{3}[0-9]{5}))$',
                                autoComplete: 'tel',
                            }}
                        />
                        <TextField
                            value={contatoInfo.Mensagem}
                            onChange={({ target }) => setContatoInfo({ ...contatoInfo, Mensagem: target.value })}
                            sx={{ mb: 3 }}
                            variant="outlined"
                            size="small"
                            focused
                            label={'Mensagem'}
                            multiline
                            rows={4}
                            placeholder="Digite aqui sua mensagem."
                            required
                        />
                        <Button variant="contained" type="submit">
                            Enviar
                        </Button>
                    </Box>
                </DialogContent>
            </Dialog>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
                onClick={() => setLoading(false)}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </>
    );
}

export default ContatoCompra;
