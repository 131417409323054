import { Box, Button, DialogTitle } from '@mui/material';
import styled from 'styled-components';
import { lightThemeMui } from '../../config/themes/light.theme';
import { Close } from '@mui/icons-material';

export const BoxMainApresentacao = styled(Box)`
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden auto;
`;

export const Header = styled(Box)`
    width: 100%;
    height: 100px;
    padding: 16px 32px;
    max-width: 1080px;
    > img {
        max-width: 200px;
    }
    @media screen and (max-width: 1120px) {
        height: unset;
        width: calc(100% - 16px);
        align-items: center;
        padding: 8px 8px;
        max-width: unset;
        > img {
            max-width: 100px;
            max-height: 20px;
        }
    }
`;
export const Content1 = styled(Box)`
    margin: 16px 0;
    display: flex;
    flex-direction: column;
    padding: 16px 32px;
    max-width: 1080px;
    width: 100%;
    /* justify-content:center;
    align-items:center;     */

    @media screen and (max-width: 1120px) {
        height: unset;
        width: calc(100% - 24px);
        padding: 8px 12px;
        max-width: unset;
    }
    @media screen and (max-height: 770px) {
        height: auto;
    }
    .buttonExperimente {
        align-self: flex-start;
        @media screen and (max-width: 1120px) {
            align-self: center;
        }
    }
`;
export const ContentVideo = styled.div`
    flex: 1;
    @media screen and (max-width: 1120px) {
        margin-top: 16px;
        min-height: 300px;
    }
    @media screen and (max-width: 800px) {
        margin-top: 16px;
        min-height: 210px;
    }
`;

export const ExperimenteDuasConsultas = styled(Box)`
    @media screen and (max-width: 1120px) {
        text-align: center;
        flex-direction: column;
        > button {
            margin-top: 8px;
            width: 100%;
        }
    }
`;
export const DivIconesApresentacao = styled(Box)`
    width: 150px;
    display: flex;
    flex-direction: column;
    justify-items: center;
    align-items: center;
    padding-top: 16px;
    padding-bottom: 16px;
    padding-left: 8px;
    padding-right: 8px;
    border: 2px solid ${lightThemeMui.palette.primary.light};
    border-radius: 20px;
`;
export const DivIconesApresentacaoVantagensPaciente = styled(Box)`
    max-width: 116px;

    display: flex;
    flex: 1;
    flex-direction: column;
    justify-items: center;
    align-items: center;
    padding-top: 16px;
    padding-bottom: 16px;
    padding-left: 8px;
    padding-right: 8px;
    border: 2px solid ${lightThemeMui.palette.primary.light};
    border-radius: 20px;
`;

export const DivPlano = styled(Box)`
    width: 100%;
    max-width: 200px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding-top: 16px;
    padding-bottom: 16px;
    padding-left: 8px;
    padding-right: 8px;
    border: 2px solid ${lightThemeMui.palette.primary.light};
    border-radius: 20px;
`;

export const DivApresentaocaoVideo = styled(Box)`
    display: flex;
    flex: 1;
    flex-direction: row;

    @media screen and (max-width: 1120px) {
        flex-direction: column;
    }
`;

export const DivTextoCalcVantagens = styled(Box)`
    @media screen and (max-width: 1120px) {
        text-align: center;
    }
`;

export const DivImgRobozinho = styled(Box)`
    @media screen and (max-width: 1120px) {
        display: none;
    }
`;

export const TitleSimulacao = styled(DialogTitle)`
    @media screen and (max-width: 1120px) {
        padding-left: 0px !important;
    }
`;
export const CloseIcon = styled(Close)`
    position: absolute;
    right: 24px;
    top: 16px;
    cursor: pointer;
    color: #858585;
    @media screen and (max-width: 1120px) {
        right: 8px;
    }
`;

export const BoxMainSimulacao = styled(Box)`
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-top: 8px;
`;

export const MicIco = ({ color, style }) => (
    <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" {...style}>
        <path
            fill={color}
            d="m12,0C5.383,0,0,5.383,0,12s5.383,12,12,12,12-5.383,12-12S18.617,0,12,0Zm0,22c-5.514,0-10-4.486-10-10S6.486,2,12,2s10,4.486,10,10-4.486,10-10,10Zm4-10h2c0,2.968-2.166,5.439-5,5.916v2.084h-2v-2.084c-2.834-.477-5-2.948-5-5.916h2c0,2.206,1.794,4,4,4s4-1.794,4-4Zm-6,0v-5c0-1.105.895-2,2-2s2,.895,2,2h-2v2h2v1h-2v2h2c0,1.105-.895,2-2,2s-2-.895-2-2Z"
        />
    </svg>
);
export const AiIco = ({ color, style }) => (
    <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" {...style}>
        <path
            fill={color}
            d="m24,7v-2h-2c0-1.654-1.346-3-3-3V0h-2v2h-2V0h-2v2h-2V0h-2v2h-2V0h-2v2c-1.654,0-3,1.346-3,3H0v2h2v2H0v2h2v2H0v2h2v2H0v2h2v3h3v2h2v-2h2v2h2v-2h2v2h2v-2h2v2h2v-2h3v-3h2v-2h-2v-2h2v-2h-2v-2h2v-2h-2v-2h2Zm-4,13H4V5c0-.552.449-1,1-1h14c.551,0,1,.448,1,1v15ZM10.876,7.18c-1.313-.55-2.651.221-2.937,1.458l-1.939,8.362h2.053l.232-1h3.43l.232,1h2.053l-1.922-8.291c-.154-.662-.575-1.266-1.202-1.528Zm-2.127,6.82l1.138-4.91c.012-.053.059-.09.113-.09s.101.037.113.09l1.138,4.91h-2.503Zm6.251-7h2v10h-2V7Z"
        />
    </svg>
);

export const JustIco = ({ color, style }) => (
    <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" {...style}>
        <path
            fill={color}
            d="M20.123,.299l-7.123,1.9V0h-2V2.732l-7.259,1.936L0,14.822v.678c0,2.481,2.019,4.5,4.5,4.5s4.5-2.019,4.5-4.5v-.678L5.817,6.184l5.183-1.382V22H4v2H20v-2h-7V4.268l4.896-1.305-2.896,7.859v.678c0,2.481,2.019,4.5,4.5,4.5s4.5-2.019,4.5-4.5v-.678L20.123,.299ZM4.5,18c-1.207,0-2.217-.86-2.449-2H6.949c-.232,1.14-1.242,2-2.449,2Zm2.066-4H2.434l2.066-5.607,2.066,5.607ZM19.5,4.393l2.066,5.607h-4.132l2.066-5.607Zm0,9.607c-1.207,0-2.217-.86-2.449-2h4.899c-.232,1.14-1.242,2-2.449,2Z"
        />
    </svg>
);
export const DipIco = ({ color, style }) => (
    <svg id="Layer_1" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" data-name="Layer 1" {...style}>
        <path
            fill={color}
            d="m11 12a1 1 0 0 1 0 2h-3a1 1 0 0 1 0-2zm6-3a1 1 0 0 0 -1-1h-8a1 1 0 0 0 0 2h8a1 1 0 0 0 1-1zm-9-3h8a1 1 0 0 0 0-2h-8a1 1 0 0 0 0 2zm12 13.444v3.833a.721.721 0 0 1 -1.231.51l-.769-.768-.769.768a.721.721 0 0 1 -1.231-.51v-3.833a3.987 3.987 0 0 1 2-7.444 3.939 3.939 0 0 1 1 .142v-7.142a3 3 0 0 0 -3-3h-8a3 3 0 0 0 -3 3v12a3 3 0 0 0 3 3h5a1 1 0 0 1 0 2h-5a5.006 5.006 0 0 1 -5-5v-12a5.006 5.006 0 0 1 5-5h8a5.006 5.006 0 0 1 5 5v8.382a3.95 3.95 0 0 1 -1 6.062zm0-3.444a2 2 0 1 0 -2 2 2 2 0 0 0 2-2z"
        />
    </svg>
);
export const CofIco = ({ color, style }) => (
    <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" {...style}>
        <path
            fill={color}
            d="M18,12c0,.552-.448,1-1,1s-1-.448-1-1,.448-1,1-1,1,.448,1,1ZM7,5c.552,0,1-.447,1-1,0-1.103,.897-2,2-2s2,.897,2,2c0,.553,.448,1,1,1s1-.447,1-1c0-2.206-1.794-4-4-4S6,1.794,6,4c0,.553,.448,1,1,1ZM24,13v2c0,1.106-.748,1.779-1.568,1.954-.661,1.654-1.872,3.051-3.432,3.954v.092c0,1.654-1.346,3-3,3-1.304,0-2.416-.836-2.829-2h-2.343c-.413,1.164-1.525,2-2.829,2-1.654,0-3-1.346-3-3v-.079c-2.635-1.519-4.182-4.377-3.983-7.451,.063-.978,.31-1.907,.705-2.756-1.017-.481-1.723-1.516-1.723-2.714,0-1.654,1.346-3,3-3,.552,0,1,.447,1,1s-.448,1-1,1-1,.448-1,1c0,.491,.356,.9,.823,.984,1.521-1.823,3.853-2.984,6.443-2.984h5.363c.733-1.478,2.102-2.512,3.927-2.946,.591-.139,1.203-.003,1.681,.374,.485,.384,.764,.958,.764,1.576v3.715c.608,.691,1.094,1.481,1.432,2.327,.821,.175,1.568,.848,1.568,1.954Zm-2,0h-.294c-.442,0-.832-.291-.958-.715-.274-.92-.78-1.776-1.463-2.477-.183-.187-.285-.438-.285-.698V5.004c-.975,.232-2.265,.83-2.764,2.335-.141,.423-.553,.714-.99,.684-.068-.003-5.98-.022-5.98-.022-3.303,0-6.05,2.459-6.253,5.599-.16,2.469,1.181,4.752,3.417,5.815,.349,.166,.57,.518,.57,.903v.683c0,.552,.449,1,1,1s1-.448,1-1,.448-1,1-1h4c.552,0,1,.447,1,1s.449,1,1,1,1-.448,1-1v-.694c0-.385,.221-.736,.569-.902,1.542-.736,2.7-2.081,3.179-3.688,.126-.424,.516-.715,.958-.715h.294v-2Z"
        />
    </svg>
);
export const SmilleIco = ({ color, style }) => (
    <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" {...style}>
        <path
            fill={color}
            d="M10,12a1,1,0,0,1-1-1c0-1.054-.68-2-1-2s-1,.946-1,2a1,1,0,0,1-2,0C5,9.108,6.232,7,8,7s3,2.108,3,4A1,1,0,0,1,10,12Zm9-1c0-1.892-1.232-4-3-4s-3,2.108-3,4a1,1,0,0,0,2,0c0-1.054.68-2,1-2s1,.946,1,2a1,1,0,0,0,2,0Zm5,1A12.013,12.013,0,0,0,12,0C-3.9.6-3.893,23.4,12,24A12.013,12.013,0,0,0,24,12Zm-2,0A10.011,10.011,0,0,1,12,22C-1.249,21.5-1.244,2.5,12,2A10.011,10.011,0,0,1,22,12Zm-4.334,3.746a1,1,0,0,0-1.33-1.493,6.36,6.36,0,0,1-8.67,0,1,1,0,0,0-1.332,1.492A9.454,9.454,0,0,0,12,18,9.454,9.454,0,0,0,17.666,15.746Z"
        />
    </svg>
);
