import React, { useEffect, useRef, useState } from 'react';
import { gerarArquivoDaGravacao, GetStream, microphoneVolumeMedio, SolicitaPermissaoMicrofone, StopStream } from '../helpers/Mic';
import { toast } from 'react-toastify';
import logoQuadrada from '../assets/logo192.png';

export const sampleRates = { min: 11025, ideal: 44100, max: 48000 };
export const maxFileSizeBytes = 6 * 1024 * 128;
export const bufferSize = 2048;
const AudioContext = window.AudioContext || window.webkitAudioContext;

function UseMicV2({ active, stream, limite = maxFileSizeBytes, onAquivoGerado, volumeRef }) {
    const [error, setError] = useState(null);
    const context = useRef(null);
    const recorderInfos = useRef({ leftchannel: [], rightchannel: [], recordingLength: 0 });
    const activeRef = useRef(active);
    const alertaVolumeBaixo = useRef(null);

    useEffect(() => {
        return () => {
            context.current?.close();
            context.current = null;
        };
    }, []);

    useEffect(() => {
        if (!active) {
            alertaVolumeBaixo.current && clearTimeout(alertaVolumeBaixo.current);
            if (context.current) {
                const { leftchannel, rightchannel, recordingLength } = recorderInfos.current;
                recorderInfos.current = { leftchannel: [], rightchannel: [], recordingLength: 0 };
                gerarArquivoDaGravacao(leftchannel, rightchannel, recordingLength, context.current.sampleRate).then((blob) => {
                    const audioURL = URL.createObjectURL(blob);
                    const nome = audioURL.replace(`blob:${window.location.origin}/`, '');
                    onAquivoGerado({ nomeArquivo: nome, audioURL, audioBlob: blob });
                });
            }
        }
        activeRef.current = active;
    }, [active]);

    function controleDeVolume(analyser, volumeMinimum = 10, tempoParaAlerta = 20000) {
        const volume = microphoneVolumeMedio(analyser);
        volumeRef.current = volume;
        if (volume < volumeMinimum) {
            if (alertaVolumeBaixo.current === null) {
                alertaVolumeBaixo.current = setTimeout(() => {
                    const mensagem =
                        'Volume do microfone muito baixo, verifique se o microfone está conectado ou se não esta mal posicionado.';
                    toast.warning(mensagem);
                    'Notification' in window &&
                        Notification.permission === 'granted' &&
                        new Notification('Microfone esta mudo!', {
                            body: mensagem,
                            icon: logoQuadrada,
                        });
                    alertaVolumeBaixo.current = null;
                }, tempoParaAlerta);
            }
        } else {
            if (alertaVolumeBaixo.current) {
                clearTimeout(alertaVolumeBaixo.current);
                alertaVolumeBaixo.current = null;
            }
        }
        return volume;
    }

    useEffect(() => {
        if (stream) {
            context.current = new AudioContext({ sampleRate: sampleRates.min });
            const sampleRate = context.current.sampleRate;
            const audioInput = context.current.createMediaStreamSource(stream);
            const analyser = context.current.createAnalyser();
            audioInput.connect(analyser);
            const recorder = context.current.createScriptProcessor(bufferSize, 2, 2);
            analyser.connect(recorder);
            recorder.connect(context.current.destination);

            recorder.onaudioprocess = async function (e) {
                if (activeRef.current === true) {
                    console.log('audio caindo no onaudioprocess');
                    controleDeVolume(analyser, 10, 20000);
                    recorderInfos.current.leftchannel.push(new Float32Array(e.inputBuffer.getChannelData(0)));
                    recorderInfos.current.rightchannel.push(new Float32Array(e.inputBuffer.getChannelData(1)));
                    recorderInfos.current.recordingLength += bufferSize;
                    if (recorderInfos.current.recordingLength >= limite) {
                        const { leftchannel, rightchannel, recordingLength } = recorderInfos.current;
                        recorderInfos.current = { leftchannel: [], rightchannel: [], recordingLength: 0 };
                        const blob = await gerarArquivoDaGravacao(leftchannel, rightchannel, recordingLength, sampleRate);
                        const audioURL = URL.createObjectURL(blob);
                        const nome = audioURL.replace(`blob:${window.location.origin}/`, '');
                        onAquivoGerado({ nomeArquivo: nome, audioURL, audioBlob: blob });
                    }
                }
            };
        }

        return () => {
            context.current?.close();
        };
    }, [stream]);

    return {
        error,
    };
}

export default UseMicV2;
