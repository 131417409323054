import React from 'react';
import { Typography, useTheme } from '@mui/material';
import styled from 'styled-components';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    height: 100vh;
    width: 100%;
`;

const ContainerInfos = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
`;

function ErroAudio({ erro }) {
    const theme = useTheme();
    return (
        <Container style={{ height: '100%', justifyContent: 'center', alignItems: 'center' }}>
            <ContainerInfos style={{ maxWidth: '800px' }}>
                <Typography
                    variant="h3"
                    fontWeight={600}
                    textAlign={'center'}
                    color={theme.palette.error.light}
                    marginBottom={'20px'}
                >
                    Ops, algo deu errado!
                </Typography>

                <Typography variant="h6" fontWeight={600} textAlign={'center'} color={theme.palette.error.dark}>
                    {erro.message}
                </Typography>
            </ContainerInfos>
        </Container>
    );
}

export default ErroAudio;
