import React, { useEffect, useRef, useState } from 'react';
import { Box, Button, Dialog, Slide, Typography, useTheme } from '@mui/material';
import { Container, VolumeIndicador } from './AudioControles.styles';
import MicIcon from '@mui/icons-material/Mic';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import TimerIcon from '@mui/icons-material/Timer';
import { toast } from 'react-toastify';
import converterSegundosParaMinutos from '../../../helpers/converteSegundoParaMinuto';
import UseMicV2 from '../../../services/useMicV2';
import { AudioR1, enumStatus, optionsAudioR1 } from '../../../Models/AudioR1';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import SelectTypeAudio from '../Components/SelectTypeAudio/SelectTypeAudio';
import SelectMicDialog from '../Components/SelectMicDialog/SelectMicDialog';
import { set } from 'react-ga';
import { use } from 'react';
import { db } from '../../../config/db';
import { useLocation, useNavigate } from 'react-router-dom';
const volumeColors = {
    error: '#f13030',
    warning: '#f1d530',
    success: '#53f130',
};
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function AudioControles({
    idAtendimento,
    audiosJaGerados,
    gravando,
    setGravando,
    streamMic,
    updateStream,
    micSelecionado,
    setMicSelecionado,
    refreshAtendimento,
}) {
    const theme = useTheme();
    const volumeRef = useRef(0);
    const audios = useRef([]);
    const [volume, setVolume] = useState(0);
    const [popUpFonteAudio, setPopUpFonteAudio] = useState(false);
    const [openSelectMic, setOpenSelectMic] = useState(false);
    const navegate = useNavigate();
    const location = useLocation();
    const { error } = UseMicV2({
        active: gravando,
        stream: streamMic,
        volumeRef,
        onAquivoGerado: (arq) =>
            (audios.current = [
                ...audios.current,
                new AudioR1({
                    name: arq.nomeArquivo,
                    atendimentoId: idAtendimento,
                    date: new Date(),
                    blob: arq.audioBlob,
                    status: enumStatus.novo,
                    options: new optionsAudioR1({
                        logFfmpeg: false,
                        logFfmpegCallback: null,
                        callbackChangeEstagio: (e) => {
                            if (e.stage === 'criandoArquivo' && e.status === 'finalizado') {
                                refreshAtendimento(idAtendimento);
                            }
                        },
                    }),
                }),
            ]),
    });

    useEffect(() => {
        const interval = setInterval(() => {
            setVolume(volumeRef.current);
        }, 500);
        return () => clearInterval(interval);
    }, [gravando]);

    useEffect(() => {
        processaAudiosIndexDB(idAtendimento);
    }, [idAtendimento]);

    async function processaAudiosIndexDB(idAtendimento) {
        try {
            const audiosIndexDB = await db.audios.where('atendimentoId').equals(idAtendimento).toArray();
            console.log('Audios dentro do index DB para este atendimento: ', audiosIndexDB);
            audiosIndexDB.forEach((arq) => {
                if (arq.status === enumStatus.local) {
                    try {
                        audios.current = [
                            ...audios.current,
                            new AudioR1({
                                name: arq.name,
                                atendimentoId: idAtendimento,
                                date: new Date(arq.date),
                                blob: arq.blob,
                                status: enumStatus.local,
                                options: new optionsAudioR1({
                                    logFfmpeg: false,
                                    logFfmpegCallback: null,
                                    callbackChangeEstagio: (e) => {
                                        if (e.stage === 'criandoArquivo' && e.status === 'finalizado') {
                                            refreshAtendimento(idAtendimento);
                                        }
                                    },
                                }),
                            }),
                        ];
                    } catch (e) {
                        console.log('Erro ao processar audio local: ', arq);
                        console.error(e);
                    }
                } else if (arq.status === enumStatus.server) {
                    try {
                        db.audios.delete(arq.name);
                    } catch (e) {
                        console.log('Erro ao deletar audio local: ', arq);
                        console.error(e);
                    }
                }
            });
        } catch (e) {
            console.error(e);
            toast.error('Erro ao buscar audios locais.');
        }
    }

    return (
        <Container color={gravando ? theme.palette.error.main : theme.palette.primary.main}>
            <Box display={'flex'}>
                <Cronometro audiosJaGerados={audiosJaGerados} gravando={gravando} setGravando={setGravando} />
                {gravando && (
                    <Box display={'flex'} alignItems={'center'}>
                        <MicIcon sx={{ color: volume > 5 ? theme.palette.error.main : theme.palette.text.main }} />
                        {/* {volume < 5 ? <MicOffIcon /> : <MicIcon />} */}
                        <MicFeedback volume={volume} />
                    </Box>
                )}
            </Box>
            <Box display={'flex'} justifyContent={'space-between'} width={'initial'}>
                <Button
                    variant="contained"
                    color={gravando ? 'error' : 'success'}
                    size="small"
                    startIcon={gravando ? <PauseIcon /> : <PlayArrowIcon />}
                    onClick={() => (gravando == false ? setPopUpFonteAudio(true) : setGravando(false))}
                    sx={{
                        boxShadow: `0px 0px 5px 0px ${gravando ? theme.palette.error.dark : theme.palette.success.dark}`,
                        margin: '0px 10px',
                    }}
                >
                    {gravando ? 'Pausar Gravação' : audiosJaGerados.length > 0 ? 'Retornar Gravação' : 'Iniciar Gravação'}
                </Button>
                {audiosJaGerados.length > 0 && (
                    <Button
                        variant="contained"
                        color={'primary'}
                        size="small"
                        startIcon={<AutoAwesomeIcon sx={{ fontSize: '10px' }} />}
                        sx={{ boxShadow: `0px 0px 5px 0px ${theme.palette.primary.dark}` }}
                        onClick={() => navegate(location.pathname.replace('gravacao', 'gerandoResumo'))}
                    >
                        Gerar Resumo
                    </Button>
                )}
            </Box>
            <Dialog
                open={popUpFonteAudio}
                TransitionComponent={Transition}
                keepMounted
                aria-describedby="alert-dialog-slide-description"
            >
                <SelectTypeAudio
                    refreshAtendimento={refreshAtendimento}
                    handleClose={() => setPopUpFonteAudio(false)}
                    audios={audios}
                    iniciarGravacao={() => setGravando(true)}
                    updateStream={updateStream}
                    atendimentoId={idAtendimento}
                    openSelectMic={() => setOpenSelectMic(true)}
                />
            </Dialog>

            <SelectMicDialog
                onClose={() => setOpenSelectMic(false)}
                open={openSelectMic}
                onSelected={setMicSelecionado}
                selectedValue={micSelecionado}
            />
        </Container>
    );
}

export default AudioControles;

function MicFeedback({ volume }) {
    const theme = useTheme();

    return (
        <Box display={'flex'} alignItems={'center'}>
            {volume < 5 ? (
                <Box display={'flex'} flexDirection={'column-reverse'}>
                    <Typography
                        variant="subtitle2"
                        fontWeight={600}
                        margin={'0px 2px'}
                        lineHeight={'1rem'}
                        color={theme.palette.text.primary}
                    >
                        Nenhum som detectado
                    </Typography>
                </Box>
            ) : (
                <>
                    <VolumeIndicador cor={volumeColors.error} ativo={volume >= 5} />
                    <VolumeIndicador cor={volumeColors.error} ativo={volume >= 15} />
                    <VolumeIndicador cor={volumeColors.warning} ativo={volume >= 30} />
                    <VolumeIndicador cor={volumeColors.warning} ativo={volume >= 50} />
                    <VolumeIndicador cor={volumeColors.success} ativo={volume >= 60} />
                    <VolumeIndicador cor={volumeColors.success} ativo={volume >= 90} />
                </>
            )}

            {/* <Box display={'flex'} flexDirection={'column-reverse'}>
                <Box
                    width={'10px'}
                    height={'8px'}
                    bgcolor={theme.palette.error.main}
                    sx={{ opacity: volume > 20 ? 1 : '0', transitionDuration: '0.5s', borderRadius: '2px' }}
                ></Box>
            </Box>
            <Box display={'flex'} flexDirection={'column-reverse'} margin={'0px 2px'}>
                <Box
                    width={'10px'}
                    height={'16px'}
                    bgcolor={theme.palette.warning.light}
                    sx={{ opacity: volume > 40 ? 1 : '0', transitionDuration: '0.5s', borderRadius: '2px' }}
                ></Box>
            </Box>
            <Box display={'flex'} flexDirection={'column-reverse'}>
                <Box
                    width={'10px'}
                    height={'24px'}
                    bgcolor={theme.palette.success.main}
                    sx={{ opacity: volume > 70 ? 1 : '0', transitionDuration: '0.5s', borderRadius: '2px' }}
                ></Box>
            </Box> */}
        </Box>
    );
}

function Cronometro({ audiosJaGerados, gravando, setGravando }) {
    const theme = useTheme();
    const [tempo, setTempo] = useState(audiosJaGerados.reduce((acc, audio) => acc + audio.duracao, 0));
    const tempoLimite = 5400; // 1h30min
    const tempoAlerta = 4200; // 1h10min

    useEffect(() => {
        if (!gravando) return;
        const timer = setInterval(() => {
            setTempo((prevState) => prevState + 1);
        }, 1000);
        return () => clearInterval(timer);
    }, [gravando]);

    useEffect(() => {
        setTempo(audiosJaGerados.reduce((acc, audio) => acc + audio.duracao, 0));
    }, [audiosJaGerados]);

    useEffect(() => {
        if (tempo >= tempoLimite) {
            setGravando(false);
            toast.error('Tempo limite de gravação atingido.');
        }
    }, [tempo]);

    return (
        <Box display={'flex'} alignItems={'center'}>
            <TimerIcon />
            <Typography
                variant="body1"
                fontWeight={600}
                margin={'0px 5px'}
                lineHeight={'1rem'}
                color={tempo > tempoAlerta ? theme.palette.error.main : theme.palette.text.primary}
            >
                {converterSegundosParaMinutos(tempo, 'reduzido')}
            </Typography>
        </Box>
    );
}
