import {
    Box,
    Button,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    IconButton,
    LinearProgress,
    Typography,
    useTheme,
} from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
// import { Container } from './styles';
// import MicIcon from '../../../../assets/recording.png';
import MultmidiaIcon from '../../../../assets/multimedia.png';
import ScreenIcon from '../../../../assets/computer-screen.png';
import styled from 'styled-components';
import { AudioR1, enumStatus, geraNomeArquivo, optionsAudioR1 } from '../../../../Models/AudioR1';
import { toast } from 'react-toastify';
import { SolicitaPermissaoMicrofone } from '../../../../helpers/Mic';
import SettingsIcon from '@mui/icons-material/Settings';
import MicIcon from '@mui/icons-material/Mic';
import AudioFileIcon from '@mui/icons-material/AudioFile';
import ScreenShareIcon from '@mui/icons-material/ScreenShare';
import { Mic } from '@mui/icons-material';
import { useOutletContext } from 'react-router-dom';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
const typesOfFile = [
    'audio/mp3',
    'audio/wav',
    'audio/ogg',
    'audio/mpeg',
    'audio/webm',
    'audio/flac',
    'audio/aac',
    'audio/aiff',
    'audio/opus',
];

const CardTipoAudio = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
    padding: 10px;
    justify-content: space-between;
    background-color: #f7f7f7;
    border-radius: 5px;
    height: 200px;
    box-shadow: 0px 0px 14px -7px #a9a9a9;
    position: relative;
    margin-bottom: 60px;
    cursor: pointer;
    user-select: none;

    > div > svg {
        transition: transform 0.5s;
    }

    &:hover {
        background-color: #eaeaea;

        > div > svg {
            transform: scale(1.2);
        }
    }
`;

const ContainerButton = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    bottom: -40px;
`;

function SelectTypeAudio({ handleClose, iniciarGravacao, updateStream, atendimentoId, openSelectMic, refreshAtendimento }) {
    const theme = useTheme();
    const inputFileRef = useRef(null);
    const [audio, setAudio] = React.useState(null);
    const [uploadAudioFileIsLoading, setUploadAudioFileIsLoading] = useState(false);
    const [logUploadFile, setLogUploadFile] = useState('Teste');
    const { createAndSetStreamMic } = useOutletContext();

    useEffect(() => {
        if (audio) {
            console.log(audio);
            setUploadAudioFileIsLoading(true);
            const reader = new FileReader();
            if (inputFileRef.current) inputFileRef.current.value = '';
            reader.onload = (e) => {
                fetch(e.target.result)
                    .then((res) => res.blob())
                    .then((blobFile) => {
                        console.log('Arquivo Selecionado sendo processado');
                        const blobName = geraNomeArquivo(blobFile);
                        new AudioR1({
                            name: blobName,
                            atendimentoId: atendimentoId,
                            date: new Date(),
                            blob: blobFile,
                            status: enumStatus.novo,
                            options: new optionsAudioR1({
                                logFfmpeg: false,
                                logFfmpegCallback: null,
                                callbackChangeEstagio: (e) => {
                                    if (e.stage === 'criandoArquivo') {
                                        setLogUploadFile(e.status);
                                        if (e.status === 'finalizado') {
                                            setTimeout(() => {
                                                setUploadAudioFileIsLoading(false);
                                                setLogUploadFile('');
                                                setAudio(null);
                                                refreshAtendimento(atendimentoId);
                                                toast.success('Arquivo de audio processado com sucesso');
                                            }, 1000);
                                        }
                                    }
                                },
                            }),
                        });
                    })
                    .catch((err) => {
                        console.error(err);
                        toast.error(
                            'Erro ao processar o arquivo de audio, verifique se o arquivo é valido, ou se o tamanho do arquivo é menor que 15MB',
                        );
                        setUploadAudioFileIsLoading(false);
                    });
            };
            reader.readAsDataURL(audio);
        }
    }, [audio]);

    const handleGravar = async () => {
        const dispositivos = await SolicitaPermissaoMicrofone();
        if (dispositivos.length === 0) return toast.error('Nenhum microfone encontrado');
        await createAndSetStreamMic(dispositivos[0].deviceId);

        iniciarGravacao();
        handleClose();
    };

    const handleEviarArquivo = () => {
        inputFileRef.current.click();
    };

    const handleGravarTela = async () => {
        try {
            const mediaStream = await navigator.mediaDevices.getDisplayMedia({
                audio: true, // Inclui o áudio da tela
            });

            handleClose();
            iniciarGravacao();
            updateStream(mediaStream);
        } catch (e) {
            toast.error('Erro ao tentar capturar a tela, verifique se a permissão foi concedida');
            console.error(e);
        }
    };

    return uploadAudioFileIsLoading ? (
        <Box
            display={'flex'}
            flexDirection={'column'}
            justifyContent={'space-between'}
            alignItems={'center'}
            padding={'20px'}
            width={'80vw'}
            maxWidth={'500px'}
            height={'300px'}
        >
            <Typography variant="h6" fontWeight={600} textAlign={'center'} marginBottom={'10px'}>
                Processando Arquivo de Audio
            </Typography>
            <Box display={'flex'} flexDirection={'column'} justifyContent={'center'} alignItems={'center'}>
                <AudioFileIcon sx={{ fontSize: '60px' }} />
                <Box display={'flex'} flexDirection={'column'}>
                    <Typography variant="body1" textAlign={'center'} marginBottom={'10px'}>
                        {audio?.name}
                    </Typography>
                    <Typography variant="body1" fontSize={'12px'} textAlign={'center'} marginBottom={'10px'}>
                        {audio ? `${(audio.size / (1024 * 1024)).toFixed(2)} MB` : '0 MB'}
                    </Typography>
                </Box>
            </Box>
            <Box width={'100%'}>
                <Typography
                    textTransform={'uppercase'}
                    fontWeight={600}
                    variant="body1"
                    textAlign={'center'}
                    marginBottom={'10px'}
                >
                    {`Status: ${logUploadFile}`}
                </Typography>
                <Box width={'100%'}>
                    <LinearProgress />
                </Box>
            </Box>
        </Box>
    ) : (
        <>
            <DialogActions>
                <IconButton aria-label="Fechar" onClick={() => handleClose()} color="error" size="small">
                    <HighlightOffIcon />
                </IconButton>
                {/* <Button variant="outlined">Cancelar</Button> */}
            </DialogActions>
            <DialogContent>
                <DialogContentText
                    id="alert-dialog-slide-description"
                    textAlign={'center'}
                    fontWeight={600}
                    marginBottom={'20px'}
                >
                    Selecione a forma que deseja adicionar um audio ao atendimento, abaixo estão as opções disponíveis
                </DialogContentText>
                <Box display="flex" marginTop={'10px'}>
                    <CardTipoAudio onClick={handleGravar}>
                        <Typography variant="h6" fontWeight={600} fontSize={'16px'} textAlign={'center'}>
                            Gravar Microfone
                        </Typography>
                        <Box display={'flex'} width={'51px'} height={'51px'} justifyContent={'center'} alignItems={'center'}>
                            <Mic sx={{ fontSize: 40 }} />
                        </Box>
                        <Typography
                            variant="body1"
                            textAlign={'center'}
                            fontSize={'12px'}
                            color={theme.palette.text.disabled}
                            display={'block'}
                            height={'75px'}
                        >
                            Grave um audio de até 90 minutos utilizando um microfone do seu dispositivo
                        </Typography>
                        <ContainerButton>
                            <Button variant="contained" color="success" size="small" startIcon={<MicIcon />}>
                                Usar Microfone
                            </Button>
                        </ContainerButton>
                    </CardTipoAudio>
                    <CardTipoAudio style={{ margin: '0px 15px' }} onClick={handleEviarArquivo}>
                        <Typography variant="h6" fontWeight={600} fontSize={'16px'} textAlign={'center'}>
                            Anexar Audio
                        </Typography>
                        <Box display={'flex'} width={'51px'} height={'51px'} justifyContent={'center'} alignItems={'center'}>
                            {/* <img width={'100%'} height={'100%'} src={MultmidiaIcon} alt="Icone Enviar Arquivo" /> */}
                            <AudioFileIcon sx={{ fontSize: 40 }} />
                        </Box>
                        <Typography
                            variant="body1"
                            textAlign={'center'}
                            fontSize={'12px'}
                            color={theme.palette.text.disabled}
                            display={'block'}
                            height={'75px'}
                        >
                            Anexe um arquivo de audio de até 90 minutos do seu dispositivo ao atendimento.
                        </Typography>
                        <ContainerButton>
                            <input
                                onChange={(e) => setAudio(e.target.files[0])}
                                type="file"
                                ref={inputFileRef}
                                style={{ display: 'none' }}
                                accept={typesOfFile.join(',')}
                            />
                            <Button variant="contained" color="success" size="small" startIcon={<AudioFileIcon />}>
                                Enviar Arquivo
                            </Button>
                        </ContainerButton>
                    </CardTipoAudio>
                    <CardTipoAudio onClick={handleGravarTela}>
                        <Typography variant="h6" fontWeight={600} fontSize={'16px'} textAlign={'center'}>
                            Gravar Tela
                        </Typography>
                        <Box display={'flex'} width={'51px'} height={'51px'} justifyContent={'center'} alignItems={'center'}>
                            {/* <img width={'100%'} height={'100%'} src={ScreenIcon} alt="Icone Gravar Tela" /> */}
                            <ScreenShareIcon sx={{ fontSize: 40 }} />
                        </Box>
                        <Typography
                            variant="body1"
                            textAlign={'center'}
                            fontSize={'12px'}
                            color={theme.palette.text.disabled}
                            display={'block'}
                            height={'75px'}
                        >
                            Grave o audio de uma tela do dispositivo (ex: chamada do zoom, google meet, teleconsulta)
                        </Typography>
                        <ContainerButton>
                            <Button variant="contained" color="success" size="small" startIcon={<ScreenShareIcon />}>
                                Gravar Tela
                            </Button>
                        </ContainerButton>
                    </CardTipoAudio>
                </Box>
                <Box display="flex">
                    <Box display={'flex'} flex={0.33} justifyContent={'center'}>
                        <Button
                            variant="outlined"
                            color="success"
                            size="small"
                            sx={{ fontSize: '12px', textTransform: 'capitalize' }}
                            startIcon={<SettingsIcon sx={{ fontSize: '10px' }} />}
                            onClick={openSelectMic}
                        >
                            Escolher Microfone
                        </Button>
                    </Box>
                </Box>
            </DialogContent>
        </>
    );
}

export default SelectTypeAudio;
