import React, { useEffect, useMemo } from 'react';
import {
    Backdrop,
    Box,
    Button,
    CircularProgress,
    Divider,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Typography,
    useTheme,
} from '@mui/material';
import axios from 'axios';
import { toast } from 'react-toastify';

import imgGarantia from '../../assets/pagamento/garantia-7-dias.png';
import imgPagarMe from '../../assets/pagamento/logo_pagarme.png';
import imgSSL from '../../assets/pagamento/IconeSiteSeguroSSLB.png';
import imgCartoes from '../../assets/pagamento/bandeiras.png';
import { init } from 'react-facebook-pixel';
import { getPacotes } from '../../services/pacote';
import pagarme from 'pagarme/browser';
import { fazVenda } from '../../services/venda';
import { useMain } from '../../contexts/MainContext';
import { set } from 'react-ga';
const configMoedaBrasil = {
    style: 'currency',
    currency: 'BRL',
};
const parcelas = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
const TesteEncryptionKey = 'ek_test_AkZrPbYYBMrX34gv4AbG45J8g1oseM';
const ProducaoEncryptionKey = 'ek_live_C68Tv4dHUWmg8yY2IXfDzSDxv6nATk';

const tiposPamento = [
    { label: 'Cartão de Crédito', value: 'credit_card' },
    { label: 'Boleto', value: 'boleto' },
];

export default function FormPagamento({ close, usuario, fazVendaProps, visiblePassword = false }) {
    const theme = useTheme();
    const [tipoPagamento, setTipoPagamento] = React.useState(tiposPamento[0].value);

    const pacoteAtual = useMemo(() => usuario?.pacote || {}, [usuario]);
    const [dadosCompra, setDadosCompra] = React.useState({
        nomeCompleto: usuario?.name || '',
        email: usuario?.email || '',
        celular: usuario?.phoneNumber || '',
        cpf: '',
        nascimento: undefined,
        cep: '',
        endereco: '',
        bairro: '',
        cidade: '',
        estado: '',
        numeroEndereco: '',
        complementoEndereco: '',
        nomeCartao: '',
        numeroCartao: '',
        cvvCartao: '',
        validadeCartaoMes: '',
        validadeCartaoAno: '',
        parcelas: 1,
        valor: 0,
        senha: '',
    });
    const [pacotes, setPacotes] = React.useState([]);
    const [pacoteSelecionado, setPacoteSelecionado] = React.useState(null);
    const [loading, setLoading] = React.useState(false);
    const listaAno = React.useMemo(() => {
        let anoAtual = new Date().getFullYear();
        let ultsDigits = String(anoAtual).substring(2, String(anoAtual).length);
        const anoFinal = parseInt(ultsDigits) + 20;
        let arrayAnos = [];
        while (ultsDigits < anoFinal) {
            arrayAnos.push(ultsDigits);
            ultsDigits++;
        }
        return arrayAnos;
    }, []);

    React.useEffect(() => {
        init(usuario);
    }, [usuario]);

    async function init(usuario) {
        try {
            const pacotes = await getPacotes();
            const pacotesFiltrados = usuario?.corporativo
                ? pacotes.filter((pacote) => pacote.idCorporativoExclusivo === usuario.corporativo.id)
                : pacotes
                      .filter((pacote) => pacote.idCorporativoExclusivo == '00000000-0000-0000-0000-000000000000')
                      .sort((a, b) => a.preco - b.preco);

            setPacotes(pacotesFiltrados);
        } catch (error) {
            console.error(error);
            toast.error('Não foi possível carregar pacotes.');
        }
    }

    async function carregaDadosCep(cep) {
        setLoading(true);
        try {
            if (cep.length == 8) {
                const responseCarregaDadosCep = await axios.get(`https://viacep.com.br/ws/${cep}/json/`);
                if (responseCarregaDadosCep.status == 200) {
                    const { data } = responseCarregaDadosCep;
                    const { logradouro, complemento, bairro, localidade, uf, erro } = data;
                    if (erro) {
                        toast.warning('CEP inválido ou não encontrado.');
                    } else {
                        setDadosCompra({
                            ...dadosCompra,
                            endereco: logradouro,
                            complementoEndereco: complemento,
                            bairro,
                            cidade: localidade,
                            estado: uf,
                        });
                    }
                }
            }
        } catch (error) {
            toast.error('Não foi possível carregar informações do cep.');
        }
        setLoading(false);
    }

    async function handleCompra() {
        setLoading(true);
        try {
            const dadosCompraFinal = {
                ...dadosCompra,
                idPacote: pacoteSelecionado.id,
                valor: 0,
                idCliente: usuario.id,
                CardHash: '',
                payment_method: tipoPagamento,
            };

            const regex = /^(?=.*[A-Z])(?=.*[\W])(?=.*[0-9])(?=.*[a-z]).{6,128}$/;

            if (visiblePassword) {
                if (dadosCompra.senha !== dadosCompra.senha2) {
                    toast.error('Senhas não conferem! Por favor, tente novamente.');
                    setLoading(false);
                    return;
                }

                if (!regex.test(dadosCompraFinal.senha)) {
                    toast.error(
                        'A senha deve conter no mínimo 6 caracteres, 1 letra maiúscula, 1 letra minúscula, 1 número e 1 caractere especial',
                    );
                    setLoading(false);
                    return;
                }
            }

            if (tipoPagamento != 'boleto') {
                const client = await pagarme.client.connect({
                    encryption_key: document.location.hostname === 'localhost' ? TesteEncryptionKey : ProducaoEncryptionKey,
                    // encryption_key: TesteEncryptionKey,
                });
                const cardHash = await client.security.encrypt({
                    card_number: dadosCompra.numeroCartao,
                    card_holder_name: dadosCompra.nomeCartao,
                    card_expiration_date: `${dadosCompra.validadeCartaoMes}/${dadosCompra.validadeCartaoAno}`,
                    card_cvv: dadosCompra.cvvCartao,
                });
                dadosCompraFinal.CardHash = cardHash;
                console.log(cardHash);
            }

            console.log(dadosCompraFinal);
            const validacao = validaCampos(dadosCompraFinal, tipoPagamento);
            if (!validacao) {
                return console.log('Dados inválidos.', dadosCompraFinal);
            }

            if (fazVendaProps && typeof fazVendaProps === 'function') {
                return fazVendaProps(dadosCompraFinal);
            }

            const responseCompra = await fazVenda(dadosCompraFinal);
            console.log(responseCompra);
            if (responseCompra === 'Success') {
                toast.success('Compra realizada com sucesso.');
                close();
            }
        } catch (error) {
            console.error(error);
            toast.error('Não foi possível realizar a compra.', { position: 'bottom-center' });
            if (error?.response?.status == 400 && error?.response?.data?.errors) {
                const objErros = error?.response?.data?.errors;
                if (Array.isArray(objErros)) {
                    objErros.forEach((erro) => {
                        toast.warning(`${erro}`, { position: 'bottom-center' });
                    });
                    return;
                }
                const result = Object.keys(objErros).map((key) => [key, objErros[key]]);
                result.forEach((erro) => {
                    erro[1].forEach((msg) => {
                        toast.warning(`Erro: ${msg}`, { position: 'bottom-center' });
                    });
                });
            } else {
                toast.warning(
                    'Por favor verifique os dados e tente novamente. Caso o erro persista entre em contato com o suporte.',
                    { position: 'bottom-center' },
                );
            }
        } finally {
            setLoading(false);
        }
    }

    function normalizaCampos(dadosCompraFinal) {
        dadosCompraFinal.celular = dadosCompraFinal.celular.replace(/\D/g, '');
        dadosCompraFinal.cpf = dadosCompraFinal.cpf.replace(/\D/g, '');
        dadosCompraFinal.cep = dadosCompraFinal.cep.replace(/\D/g, '');
        dadosCompraFinal.numeroEndereco = dadosCompraFinal.numeroEndereco.replace(/\D/g, '');
        dadosCompraFinal.numeroCartao = dadosCompraFinal.numeroCartao.replace(/\D/g, '');
        dadosCompraFinal.cvvCartao = dadosCompraFinal.cvvCartao.replace(/\D/g, '');
        return dadosCompraFinal;
    }

    function validaCampos(dadosCompraFinal, tipoPagamento = 'credit_card') {
        if (dadosCompraFinal.nomeCompleto.length < 3) {
            toast.warning('Nome inválido.');
            return false;
        }
        if (dadosCompraFinal.email.length < 3 && !dadosCompraFinal.email.includes('@')) {
            toast.warning('Email inválido.');
            return false;
        }
        if (dadosCompraFinal.celular.length < 11) {
            toast.warning('Celular inválido.');
            return false;
        }
        if (dadosCompraFinal.nascimento.length < 10) {
            toast.warning('Data de nascimento inválida.');
            return false;
        }
        if (!regexValidaCPF(dadosCompraFinal.cpf) && !regexValidaCNPJ(dadosCompraFinal.cpf)) {
            toast.warning('CPF ou CNPJ inválido.');
            return false;
        }
        if (dadosCompraFinal.cep.length < 8) {
            toast.warning('CEP inválido.');
            return false;
        }
        if (dadosCompraFinal.numeroEndereco.length < 1) {
            toast.warning('Número do endereço inválido.');
            return false;
        }
        if (dadosCompraFinal.endereco.length < 3) {
            toast.warning('Endereço inválido.');
            return false;
        }
        if (dadosCompraFinal.bairro.length < 3) {
            toast.warning('Bairro inválido.');
            return false;
        }
        if (dadosCompraFinal.cidade.length < 3) {
            toast.warning('Cidade inválida.');
            return false;
        }
        if (dadosCompraFinal.estado.length < 2) {
            toast.warning('Estado inválido.');
            return false;
        }

        if (tipoPagamento == 'credit_card') {
            if (dadosCompraFinal.nomeCartao.length < 3) {
                toast.warning('Nome no cartão inválido.');
                return false;
            }
            if (dadosCompraFinal.numeroCartao.length < 16) {
                toast.warning('Número do cartão inválido.');
                return false;
            }
            if (dadosCompraFinal.cvvCartao.length < 3) {
                toast.warning('CVV do cartão inválido.');
                return false;
            }
            if (dadosCompraFinal.validadeCartaoMes.length < 2) {
                toast.warning('Mês de validade do cartão inválido.');
                return false;
            }
            if (dadosCompraFinal.validadeCartaoAno.length < 2) {
                toast.warning('Ano de validade do cartão inválido.');
                return false;
            }
            if (dadosCompraFinal.parcelas.length < 1) {
                toast.warning('Parcelas inválidas.');
                return false;
            }
        }
        return true;
    }

    function regexValidaCPF(cpf) {
        return /^[0-9]{3}\.?[0-9]{3}\.?[0-9]{3}\-?[0-9]{2}$/.test(cpf);
    }

    function regexValidaCNPJ(cnpj) {
        return /^[0-9]{2}\.?[0-9]{3}\.?[0-9]{3}\/?[0-9]{4}\-?[0-9]{2}$/.test(cnpj);
    }

    if (pacoteSelecionado === null)
        return (
            <Box display={'flex'} flexDirection={'column'} flex={1}>
                <Typography variant="h4" fontSize={20} fontWeight={600} textAlign={'center'} color={theme.palette.primary.main}>
                    Planos de Assinatura Disponiveis
                </Typography>

                <Box
                    display={'flex'}
                    flexDirection={'row'}
                    flex={1}
                    overflowY={'auto'}
                    overflowX={'hidden'}
                    sx={{
                        '@media (max-width: 780px)': {
                            flexDirection: 'column',
                        },
                    }}
                >
                    {pacotes.map((pacote) => (
                        <Box
                            key={pacote.Id}
                            display={'flex'}
                            flexDirection={'column'}
                            justifyContent={'space-between'}
                            alignItems={'center'}
                            height={'100%'}
                            p={2}
                            position={'relative'}
                            border={'1px solid #cecece'}
                            borderRadius={5}
                            m={2}
                            onClick={() => {
                                if (pacoteAtual.id === pacote.id) {
                                    toast.warning('Você já possui este plano.');
                                    toast.info('Selecione outro plano para continuar.');
                                    return;
                                }
                                setPacoteSelecionado(pacote);
                            }}
                            gap={'10px'}
                            title={`Clique para selecionar o plano ${pacote.nome}`}
                            sx={{
                                cursor: 'pointer',
                                '&:hover': {
                                    backgroundColor: '#f4e5ff',
                                },
                                backgroundColor: pacoteAtual.id === pacote.id ? '#f4e5ff' : '#fff',
                            }}
                        >
                            {pacoteAtual.id === pacote.id && (
                                <Box
                                    sx={{
                                        position: 'absolute',
                                        top: -20,
                                        left: 0,
                                        width: '100%',
                                        display: 'flex',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <Box
                                        sx={{
                                            backgroundColor: theme.palette.primary.main,
                                            padding: '6px 12px',
                                            borderRadius: 5,
                                        }}
                                    >
                                        <Typography fontWeight={600} fontSize={'14px'} variant="h6" color={'#fff'}>
                                            Seu Plano atual
                                        </Typography>
                                    </Box>
                                </Box>
                            )}
                            <Typography variant="h5" textAlign={'center'} fontWeight={600} color={theme.palette.primary.main}>
                                {pacote.nome}
                            </Typography>
                            <span style={{ textAlign: 'center' }}>
                                Este plano te da direito a fazer{' '}
                                <b style={{ color: theme.palette.primary.main, fontWeight: 500 }}>
                                    {pacote.credito} consultas mês.
                                </b>
                            </span>
                            <Typography variant="h6" fontWeight={600} textAlign={'center'} color={theme.palette.success.main}>
                                {parseInt(pacote.preco / 100).toLocaleString('pt-br', configMoedaBrasil)}
                                {' Mensal'}
                            </Typography>
                        </Box>
                    ))}
                </Box>
            </Box>
        );

    return (
        <Box
            component={'form'}
            display={'flex'}
            flexDirection={'column'}
            onSubmit={(e) => {
                e.preventDefault();
                handleCompra();
            }}
        >
            <Divider sx={{ mt: 2, mb: 2 }} />
            <Box display={'flex'} flexDirection={'column'}>
                <Box
                    display={'flex'}
                    flex={1}
                    justifyContent={'space-between'}
                    sx={{
                        '@media (max-width: 780px)': {
                            flexDirection: 'column',
                        },
                    }}
                >
                    <Typography variant="body1" fontSize={20}>
                        Plano Selecionado:
                    </Typography>
                    <Box display={'flex'} alignItems={'flex-end'} gap={'5px'}>
                        <Typography
                            variant="subtitle2"
                            fontWeight={400}
                            fontSize={12}
                            color={theme.palette.grey[400]}
                            sx={{
                                textDecoration: 'underline',
                                '&:hover': {
                                    cursor: 'pointer',
                                    color: theme.palette.primary.dark,
                                },
                            }}
                            title={'Clique para trocar de plano'}
                            onClick={() => setPacoteSelecionado(null)}
                        >
                            Trocar Plano
                        </Typography>
                        <Typography variant="body1" fontSize={20} fontWeight={'bold'} color={theme.palette.primary.main}>
                            {`${pacoteSelecionado ? pacoteSelecionado.nome : 'Plano não selecionado'}`}
                        </Typography>
                    </Box>
                </Box>
                <Box
                    display={'flex'}
                    flex={1}
                    justifyContent={'space-between'}
                    sx={{
                        '@media (max-width: 780px)': {
                            flexDirection: 'column',
                        },
                    }}
                >
                    <Typography variant="body1" fontSize={20}>
                        Por:
                    </Typography>
                    <Typography variant="body1" fontSize={20} fontWeight={'bold'} color={theme.palette.primary.main}>
                        12 x {(pacoteSelecionado.preco / 100 / 12).toLocaleString('pt-br', configMoedaBrasil)}
                    </Typography>
                </Box>
                <Box
                    display={'flex'}
                    flex={1}
                    justifyContent={'space-between'}
                    sx={{
                        '@media (max-width: 780px)': {
                            flexDirection: 'column',
                        },
                    }}
                >
                    <Typography variant="body1" fontSize={20}>
                        Total:
                    </Typography>
                    <Typography variant="body1" fontSize={20} color={theme.palette.success.main} fontWeight={'bold'}>
                        {(pacoteSelecionado.preco / 100).toLocaleString('pt-br', configMoedaBrasil)}
                    </Typography>
                </Box>
            </Box>
            <Divider sx={{ mt: 2, mb: 2 }} />
            <Typography variant="body1" fontSize={25} textAlign={'center'}>
                Dados de cadastro
            </Typography>
            <Divider sx={{ mt: 2, mb: 2 }} />
            <TextField
                sx={{ mb: 2 }}
                type="text"
                focused
                size="small"
                required
                defaultValue={dadosCompra.nomeCompleto}
                label={'Nome Completo'}
                placeholder="Nome completo"
                onBlur={(event) => {
                    setDadosCompra({ ...dadosCompra, nomeCompleto: event.target.value });
                }}
            />
            <TextField
                sx={{ mb: 2 }}
                focused
                size="small"
                required
                type="email"
                defaultValue={dadosCompra.email}
                label={'Email'}
                placeholder="Email"
                onBlur={(event) => {
                    setDadosCompra({ ...dadosCompra, email: event.target.value });
                }}
            />
            {visiblePassword && (
                <>
                    <Box display={'flex'} flexDirection={'column'}>
                        <TextField
                            focused
                            size="small"
                            required
                            type="password"
                            defaultValue={dadosCompra.senha}
                            label={'Senha'}
                            placeholder="Senha"
                            onBlur={(event) => {
                                setDadosCompra({ ...dadosCompra, senha: event.target.value });
                            }}
                        />
                        <Typography variant="caption" fontSize={12} color={theme.palette.grey[400]} sx={{ mb: 2 }}>
                            A senha deve conter no mínimo 6 caracteres, 1 letra maiúscula, 1 letra minúscula, 1 número e 1
                            caractere especial
                        </Typography>
                    </Box>
                    <TextField
                        sx={{ mb: 2 }}
                        focused
                        size="small"
                        required
                        type="password"
                        defaultValue={dadosCompra.senha}
                        label={'Repita a Senha'}
                        placeholder="Repita a Senha"
                        onBlur={(event) => {
                            setDadosCompra({ ...dadosCompra, senha2: event.target.value });
                        }}
                    />
                </>
            )}
            <TextField
                sx={{ mb: 2 }}
                required
                focused
                size="small"
                type="texto"
                label={'Celular'}
                title="ex: 21988887777"
                defaultValue={dadosCompra.celular}
                placeholder="Celular com DDD ex: 21984438518"
                inputProps={{
                    maxLength: 20,
                    'x-autocompletetype': 'tel',
                    pattern: '^((1[1-9])|([2-9][0-9]))((3[0-9]{3}[0-9]{4})|(9[0-9]{3}[0-9]{5}))$',
                    autoComplete: 'tel',
                }}
                value={dadosCompra.celular}
                onBlur={(event) => {
                    setDadosCompra({ ...dadosCompra, celular: String(event.target.value).replace(/\s/g, '').replace(/\D/g, '') });
                }}
                onChange={(event) => {
                    setDadosCompra({ ...dadosCompra, celular: String(event.target.value).replace(/\s/g, '').replace(/\D/g, '') });
                }}
            />
            <TextField
                sx={{ mb: 2 }}
                focused
                size="small"
                required
                type="date"
                initialState={dadosCompra.nascimento}
                label={'Nascimento'}
                placeholder="Data de Nascimento"
                onBlur={(event) => {
                    setDadosCompra({ ...dadosCompra, nascimento: event.target.value });
                }}
                inputProps={{ min: '1900-01-01', max: '9999-01-01' }}
            />
            <TextField
                sx={{ mb: 2 }}
                required
                focused
                size="small"
                type="text"
                label={'CPF/CNPJ'}
                title="Preencha um cpf ou cpnj válido"
                placeholder="CPF ou CNPJ"
                inputProps={{ minLength: '11', maxlength: '18' }}
                value={dadosCompra.cpf}
                onBlur={(event) => {
                    setDadosCompra({ ...dadosCompra, cpf: event.target.value.replace(/\D/g, '') });
                }}
                onChange={(event) => {
                    setDadosCompra({ ...dadosCompra, cpf: event.target.value.replace(/\D/g, '') });
                }}
            />
            <TextField
                sx={{ mb: 2 }}
                required
                focused
                size="small"
                type="text"
                label={'Cep'}
                title="Preencha um CEP válido"
                placeholder="CEP, somente números ex: 22229360"
                inputProps={{ minLength: '8', maxlength: '8', pattern: '^[0-9]*$' }}
                value={dadosCompra.cep}
                onBlur={(event) => {
                    carregaDadosCep(event.target.value);
                }}
                onChange={(event) => {
                    setDadosCompra({ ...dadosCompra, cep: event.target.value.replace(/\D/g, '') });
                }}
            />

            <TextField
                sx={{ mb: 2 }}
                required
                focused
                size="small"
                type="text"
                title="Esses dados são preenchidos automaticamente mediante o CEP"
                label={'Nome do logradouro (Rua, avenida)'}
                placeholder="Endereço preenchido automaticamente de acordo com CEP"
                inputProps={{ maxLength: 50 }}
                value={dadosCompra.endereco}
                onChange={(event) => {
                    setDadosCompra({ ...dadosCompra, endereco: event.target.value });
                }}
            />
            <TextField
                sx={{ mb: 2 }}
                required
                focused
                size="small"
                type="text"
                label={'Número'}
                title="Preencha somente números"
                placeholder="Somente números"
                inputProps={{ maxlength: '20', pattern: '^[0-9]*$' }}
                value={dadosCompra.numeroEndereco}
                onChange={(event) => {
                    setDadosCompra({ ...dadosCompra, numeroEndereco: event.target.value.replace(/\D/g, '') });
                }}
                onBlur={(event) => {
                    setDadosCompra({ ...dadosCompra, numeroEndereco: event.target.value.replace(/\D/g, '') });
                }}
            />
            <TextField
                sx={{ mb: 2 }}
                required
                focused
                size="small"
                type="text"
                title="Esses dados são preenchidos automaticamente mediante o CEP"
                label={'Bairro'}
                placeholder="Bairro"
                inputProps={{ maxLength: 20 }}
                value={dadosCompra.bairro}
                onChange={(event) => {
                    setDadosCompra({ ...dadosCompra, bairro: event.target.value });
                }}
            />
            <TextField
                sx={{ mb: 2 }}
                required
                focused
                size="small"
                type="text"
                title="Esses dados são preenchidos automaticamente mediante o CEP"
                label={'Cidade'}
                placeholder="Cidade"
                inputProps={{ maxLength: 20 }}
                value={dadosCompra.cidade}
                onChange={(event) => {
                    setDadosCompra({ ...dadosCompra, cidade: event.target.value });
                }}
            />
            <FormControl required focused size="small" sx={{ mb: 2 }}>
                <InputLabel>Estado</InputLabel>
                <Select
                    label={'Estado'}
                    onChange={(event) => {
                        setDadosCompra({ ...dadosCompra, estado: event.target.value });
                    }}
                    value={dadosCompra.estado}
                >
                    {listaEstados.map((estado) => (
                        <MenuItem value={estado.value}>{estado.estado}</MenuItem>
                    ))}
                </Select>
            </FormControl>
            <Divider sx={{ mt: 2, mb: 2 }} />
            <Typography variant="body1" fontSize={25} textAlign={'center'}>
                Dados de Pagamento
            </Typography>

            <Divider sx={{ mt: 2, mb: 2 }} />

            <Box mb={2}>
                <FormControl focused required fullWidth>
                    <InputLabel>Metodo de Pagamento</InputLabel>
                    <Select
                        value={tipoPagamento}
                        label={'Metodo de Pagamento'}
                        sx={{ minWidth: 80 }}
                        size="small"
                        onChange={(event) => setTipoPagamento(event.target.value)}
                    >
                        {tiposPamento.map((tipo) => (
                            <MenuItem value={tipo.value}>{tipo.label}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Box>
            {tipoPagamento == tiposPamento[0].value && (
                <>
                    <TextField
                        sx={{ mb: 2 }}
                        required
                        focused
                        size="small"
                        type="text"
                        title="Preencha este campo exatamente como consta o nome no cartão."
                        label={'Nome no cartão'}
                        placeholder="Nome escrito no cartão"
                        inputProps={{ maxLength: 30, autoComplete: 'cc-name', 'x-autocompletetype': 'cc-name' }}
                        value={dadosCompra.nomeCartao}
                        onChange={(event) => {
                            setDadosCompra({ ...dadosCompra, nomeCartao: event.target.value });
                        }}
                    />
                    <TextField
                        sx={{ mb: 2 }}
                        required
                        focused
                        size="small"
                        type="number"
                        title="Preencha este campo com número que consta em seu cartão."
                        label={'Número no cartão'}
                        placeholder="Número do cartão"
                        inputProps={{ min: 1, autoComplete: 'cc-number', 'x-autocompletetype': 'cc-number' }}
                        value={dadosCompra.numeroCartao}
                        onChange={(event) => {
                            setDadosCompra({ ...dadosCompra, numeroCartao: event.target.value });
                        }}
                    />
                    <TextField
                        sx={{ mb: 2 }}
                        required
                        focused
                        size="small"
                        type="text"
                        title="Preencha este campo com número que consta em seu cartão."
                        label={'Código de segurança CVV'}
                        placeholder="CVV até 4 digitos"
                        inputProps={{
                            minLength: 3,
                            maxLength: 4,
                            autoComplete: 'cc-number',
                            'x-autocompletetype': 'cc-number',
                            pattern: '[0-9]*',
                        }}
                        value={dadosCompra.cvvCartao}
                        onChange={(event) => {
                            setDadosCompra({
                                ...dadosCompra,
                                cvvCartao: event.target.value.replace(/\s/g, '').replace(/\D/g, ''),
                            });
                        }}
                    />
                    <Box mb={2}>
                        <InputLabel color="primary" sx={{ mb: 2 }}>
                            Validade do cartão
                        </InputLabel>
                        <FormControl focused required>
                            <InputLabel>Mês</InputLabel>
                            <Select
                                value={dadosCompra.validadeCartaoMes}
                                label={'Mês'}
                                sx={{ minWidth: 80, mr: 2 }}
                                size="small"
                                onChange={(event) => {
                                    setDadosCompra({ ...dadosCompra, validadeCartaoMes: event.target.value });
                                }}
                            >
                                <MenuItem value=""></MenuItem>
                                <MenuItem value="01">01</MenuItem>
                                <MenuItem value="02">02</MenuItem>
                                <MenuItem value="03">03</MenuItem>
                                <MenuItem value="04">04</MenuItem>
                                <MenuItem value="05">05</MenuItem>
                                <MenuItem value="06">06</MenuItem>
                                <MenuItem value="07">07</MenuItem>
                                <MenuItem value="08">08</MenuItem>
                                <MenuItem value="09">09</MenuItem>
                                <MenuItem value="10">10</MenuItem>
                                <MenuItem value="11">11</MenuItem>
                                <MenuItem value="12">12</MenuItem>
                            </Select>
                        </FormControl>
                        <FormControl focused required>
                            <InputLabel>Ano</InputLabel>
                            <Select
                                value={dadosCompra.validadeCartaoAno}
                                label={'Ano'}
                                sx={{ minWidth: 80 }}
                                size="small"
                                onChange={(event) => {
                                    setDadosCompra({ ...dadosCompra, validadeCartaoAno: event.target.value });
                                }}
                            >
                                <MenuItem value={''}></MenuItem>
                                {listaAno.map((ano) => (
                                    <MenuItem key={ano} value={String(ano)}>
                                        {ano}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Box>
                </>
            )}

            <Typography variant="body1" fontSize={25} textAlign={'center'}>
                Resumo da compra
            </Typography>
            <Divider sx={{ mt: 2, mb: 2 }} />
            <Box display={'flex'} justifyContent={'space-between'}>
                <Typography fontWeight={'bold'}>
                    {pacoteSelecionado ? pacoteSelecionado.nome : 'Plano não selecionado'}
                </Typography>
                <Typography fontWeight={'bold'}>
                    {parseInt(pacoteSelecionado.preco / 100).toLocaleString('pt-br', configMoedaBrasil)}
                </Typography>
            </Box>
            <Box display={'flex'} justifyContent={'space-between'} mb={2}>
                <Typography fontWeight={'bold'}>Total Parcela em</Typography>
                <Typography fontWeight={'bold'} color={theme.palette.primary.main}>{`${dadosCompra.parcelas}x ${(
                    pacoteSelecionado.preco /
                    100 /
                    dadosCompra.parcelas
                ).toLocaleString('pt-br', configMoedaBrasil)}`}</Typography>
            </Box>
            <Button variant="contained" type="submit">
                Assinar
            </Button>
            <Box display={'flex'} mt={2} mb={'15px'} ml={0} mr={0} height={'60px'} width={'100%'} overflow={'hidden'}>
                <img style={{ height: '100%' }} src={imgGarantia} alt="Garantia de 7 dias." />
                <img style={{ height: '100%' }} src={imgPagarMe} alt="Pagar Me" />
                <img style={{ height: '100%' }} src={imgSSL} alt="SSL" />
                <img style={{ width: '100%' }} src={imgCartoes} alt="Cartoes" />
            </Box>
            <Typography textAlign={'center'} variant="caption">
                © 2023-2024 R1 MED
            </Typography>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
                onClick={() => setLoading(false)}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </Box>
    );
}

export const listaEstados = [
    { value: '', estado: 'Selecione o estado' },
    { value: 'AC', estado: 'Acre' },
    { value: 'AL', estado: 'Alagoas' },
    { value: 'AP', estado: 'Amapá' },
    { value: 'AM', estado: 'Amazonas' },
    { value: 'BA', estado: 'Bahia' },
    { value: 'CE', estado: 'Ceará' },
    { value: 'DF', estado: 'Distrito Federal' },
    { value: 'ES', estado: 'Espírito Santo' },
    { value: 'GO', estado: 'Goiás' },
    { value: 'MA', estado: 'Maranhão' },
    { value: 'MT', estado: 'Mato Grosso' },
    { value: 'MS', estado: 'Mato Grosso do Sul' },
    { value: 'MG', estado: 'Minas Gerais' },
    { value: 'PA', estado: 'Pará' },
    { value: 'PB', estado: 'Paraíba' },
    { value: 'PR', estado: 'Paraná' },
    { value: 'PE', estado: 'Pernambuco' },
    { value: 'PI', estado: 'Piauí' },
    { value: 'RJ', estado: 'Rio de Janeiro' },
    { value: 'RN', estado: 'Rio Grande do Norte' },
    { value: 'RS', estado: 'Rio Grande do Sul' },
    { value: 'RO', estado: 'Rondônia' },
    { value: 'RR', estado: 'Roraima' },
    { value: 'SC', estado: 'Santa Catarina' },
    { value: 'SP', estado: 'São Paulo' },
    { value: 'SE', estado: 'Sergipe' },
    { value: 'TO', estado: 'Tocantins' },
];
