import React from 'react';
import {
    Box,
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    Divider,
    FormControl,
    InputLabel,
    Slider,
    TextField,
    Typography,
    Backdrop,
    CircularProgress,
    InputAdornment,
    useMediaQuery,
} from '@mui/material';
import logo from '../../assets/r1medlogo_small.png';
import { useNavigate } from 'react-router-dom';
import {
    AiIco,
    BoxMainApresentacao,
    BoxMainSimulacao,
    CloseIcon,
    CofIco,
    Content1,
    ContentVideo,
    DipIco,
    DivApresentaocaoVideo,
    DivIconesApresentacao,
    DivIconesApresentacaoVantagensPaciente,
    DivImgRobozinho,
    DivPlano,
    DivTextoCalcVantagens,
    ExperimenteDuasConsultas,
    Header,
    JustIco,
    MicIco,
    SmilleIco,
    TitleSimulacao,
} from './apresentacao.style';
import { lightThemeMui } from '../../config/themes/light.theme';
import robozinho from '../../assets/apresentacao/Robozinho.png';
import { enviaContato } from '../../services/contato';
import { toast } from 'react-toastify';
import { styled } from '@mui/material/styles';
import { useLocation } from 'react-router-dom';
import ReactPixel from 'react-facebook-pixel';
import ReactGA from 'react-ga';
import { getPacotes } from '../../services/pacote';
import iconeAmbulancia from '../../assets/iconesEspecialiades/ambulancia.svg';
import iconeCirurgiaPlastica from '../../assets/iconesEspecialiades/cirurgia-plastica.svg';
import iconeFarmacia from '../../assets/iconesEspecialiades/farmacia.svg';
import iconeRaioX from '../../assets/iconesEspecialiades/raios-x.svg';
import iconeSalaCirurgia from '../../assets/iconesEspecialiades/sala-de-cirurgia2.svg';
import iconeSaude from '../../assets/iconesEspecialiades/saude.svg';
import iconeTools from '../../assets/iconesEspecialiades/tools.svg';
import { useTheme } from '@mui/system';
import ContatoCompra from '../../elementos/ContatoCompra/ContatoCompra';

const IOSSlider = styled(Slider)(({ theme }) => ({
    // color: theme.palette.mode === 'dark' ? '#0a84ff' : '#007bff',
    height: 5,
    padding: '15px 0',
    '& .MuiSlider-thumb': {
        height: 20,
        width: 20,
        backgroundColor: '#fff',
        boxShadow: '0 0 2px 0px rgba(0, 0, 0, 0.1)',
        '&:focus, &:hover, &.Mui-active': {
            boxShadow: '0px 0px 3px 1px rgba(0, 0, 0, 0.1)',
            // Reset on touch devices, it doesn't add specificity
            '@media (hover: none)': {
                boxShadow: '0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.13),0 0 0 1px rgba(0,0,0,0.02)',
            },
        },
        '&:before': {
            boxShadow: '0px 0px 1px 0px rgba(0,0,0,0.2), 0px 0px 0px 0px rgba(0,0,0,0.14), 0px 0px 1px 0px rgba(0,0,0,0.12)',
        },
    },
    '& .MuiSlider-valueLabel': {
        fontSize: 12,
        fontWeight: 'normal',
        top: -6,
        backgroundColor: 'unset',
        color: theme.palette.text.primary,
        '&::before': {
            display: 'none',
        },
        '& *': {
            background: 'transparent',
            color: theme.palette.mode === 'dark' ? '#fff' : '#000',
        },
    },
    '& .MuiSlider-track': {
        border: 'none',
        height: 5,
    },
    '& .MuiSlider-rail': {
        opacity: 0.5,
        boxShadow: 'inset 0px 0px 4px -2px #000',
        backgroundColor: '#d0d0d0',
    },
}));
export default function Apresentacao() {
    const location = useLocation();
    const theme = useTheme();
    React.useEffect(() => {
        console.log('Ativando Pixel analytics');
        ReactPixel.pageView();

        console.log('Ativando Google analytics.');
        ReactGA.pageview(location.pathname + location.search);
    }, [location]);

    const navegate = useNavigate();
    const isSmallScreen = useMediaQuery('(max-width:800px)');
    const [mediaConstultaSemana, setMediaConstultaSemana] = React.useState(90);
    const [faturamentoMedioConsulta, setFaturamentoMedioConsulta] = React.useState(500);
    const [tempoMedioConsulta, setTempoMedioConsulta] = React.useState(30);
    const [percentReinvestirNovasConsultas, setPercentReinvestirNovasConsultas] = React.useState(70);
    const [pacotes, setPacotes] = React.useState([]);

    React.useEffect(() => {
        async function init() {
            try {
                // const response = await getPacotes();
                // console.log('Pacotes', response);
                const pacoteUnico = {
                    id: '4e61da0d-d972-4ada-9d0a-022d9f16f5ee',
                    nome: 'Planos Anual',
                    preco: 298800,
                    credito: 500,
                    idCorporativoExclusivo: null,
                };
                // setPacotes(response.filter((e) => e.idCorporativoExclusivo == '00000000-0000-0000-0000-000000000000'));
                setPacotes([pacoteUnico]);
            } catch (error) {
                console.error(error);
            }
        }
        console.log('Buscando pacotes');
        init();
    }, []);

    const [contatoInfo, setContatoInfo] = React.useState({
        Nome: '',
        Celular: '',
        Mensagem: '',
    });

    const [loading, setLoading] = React.useState(false);
    const [openCalculoUsoR1, setOpenCalculoUsoR1] = React.useState(false);
    const [openQuemSomos, setOpenQuemSomos] = React.useState(false);
    const [openPoliticaPrivacidade, setOpenPoliticaPrivacidade] = React.useState(false);
    const [openContato, setOpenContato] = React.useState(false);

    function formatarTempo(segundos) {
        var minutos = Math.floor(segundos / 60);
        var segundosRestantes = segundos % 60;
        return minutos + ' minutos e ' + Math.round(segundosRestantes) + ' segundos';
    }
    function formatarTempoHoras(segundos) {
        var horas = Math.floor(segundos / 3600);
        var minutos = Math.floor((segundos % 3600) / 60);
        var segundosRestantes = segundos % 60;

        // Adicione um zero à frente dos números menores que 10
        var formatoHoras = horas < 10 ? '0' + horas : horas;
        var formatoMinutos = minutos < 10 ? '0' + minutos : minutos;
        var formatoSegundos =
            Math.round(segundosRestantes) < 10 ? '0' + Math.round(segundosRestantes) : Math.round(segundosRestantes);

        return formatoHoras + ' horas, ' + formatoMinutos + ' minutos';
    }

    const BotaoExeperimente = (props) => (
        <Button
            {...props}
            sx={{ ...props.sx, textTransform: 'uppercase', height: '60px', width: '300px', fontSize: '16px' }}
            variant="contained"
        >
            Experimente grátis
        </Button>
    );
    //#region CALCULOS UTILIZAÇÃO R1

    function calcTempoMedioGastoDocumentacao() {
        const result = (tempoMedioConsulta * 25) / 100;
        if (result > 9) return 9;
        return result;
    }

    function calcTempoUsoMedioR1() {
        const result = calcTempoMedioGastoDocumentacao(tempoMedioConsulta) / 3;
        if (result > 3.5) return 3.5;
        return result;
    }

    function calcEconomiaTempoSemanaUsoR1() {
        return ((calcTempoMedioGastoDocumentacao() - calcTempoUsoMedioR1()) * mediaConstultaSemana) / 60;
    }

    function calcEconomiaHorasMes() {
        return calcEconomiaTempoSemanaUsoR1() * 4;
    }

    function calcHorasReservadasNovaConsulta() {
        return (calcEconomiaHorasMes() * percentReinvestirNovasConsultas) / 100;
    }

    function calcHorasReservadasUsoPessoal() {
        return calcEconomiaHorasMes() - calcHorasReservadasNovaConsulta();
    }

    function calcAumentoConsultasMensal() {
        let resultCalc = (calcHorasReservadasNovaConsulta() * 60) / tempoMedioConsulta;
        if (isNaN(resultCalc)) {
            return 0;
        }
        return resultCalc;
    }

    function calcAumentoFaturamentoMensal() {
        return calcAumentoConsultasMensal() * faturamentoMedioConsulta;
    }

    function calcRoi() {
        let resultCalc = calcAumentoFaturamentoMensal() / (mediaConstultaSemana + calcAumentoConsultasMensal() * 3.5);
        if (isNaN(resultCalc)) {
            return 0;
        }
        return resultCalc;
    }

    //#endregion

    //#region COMPONENTS REPETIDOS

    async function EnviarContato(event) {
        setLoading(true);
        try {
            event.preventDefault();
            const { Nome, Celular, Mensagem } = contatoInfo;
            await enviaContato(Nome, Celular, Mensagem);
            toast.success('Mensagem enviado com sucesso');
            setOpenContato(false);
            setContatoInfo({
                Nome: '',
                Celular: '',
                Mensagem: '',
            });
        } catch (error) {
            console.error(error);
            toast.error('Erro ao enviar email.');
        }
        setLoading(false);
    }

    //#endregion
    return (
        <BoxMainApresentacao>
            <Header id="HEADER" display={'flex'} justifyContent={'space-between'}>
                <img src={logo} />
                <Box display={'flex'}>
                    <Button variant="contained" onClick={() => navegate('/login')}>
                        Login
                    </Button>
                </Box>
            </Header>
            {isSmallScreen ? (
                <Content1 id="CONTENT_1">
                    <DivApresentaocaoVideo>
                        <Box flex={1} maxWidth={'750px'} mr={2} display={'flex'} flexDirection={'column'}>
                            <Typography
                                variant="body1"
                                fontSize={isSmallScreen ? 16 : 22}
                                children={'OTIMIZE SUAS CONSULTAS COM IA'}
                            />
                            <Typography
                                variant="h1"
                                fontSize={isSmallScreen ? 44 : 52}
                                fontWeight="bold"
                                children={'Economize seu tempo no registro do prontuário do paciente.'}
                                mb={2}
                            />
                            <ContentVideo>
                                <iframe
                                    width={'100%'}
                                    height={'100%'}
                                    src="https://www.youtube.com/embed/z9yhQ1TChgQ?si=XcJbOXzvSvbUtkU1"
                                    title="YouTube video player"
                                    frameBorder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                    allowFullScreen
                                ></iframe>
                            </ContentVideo>
                            <Typography
                                variant="body1"
                                fontSize={isSmallScreen ? 16 : 20}
                                fontWeight={400}
                                mt={2}
                                color={'#6f6f6f'}
                                children={
                                    'Que tal um R1 para chamar de seu? Tenha seu assistente pessoal com Inteligência Artificial para documentação clínica automatizada.'
                                }
                                mb={2}
                            />
                        </Box>
                    </DivApresentaocaoVideo>
                    <BotaoExeperimente
                        sx={{ marginTop: '8px' }}
                        onClick={() => setOpenContato(true)}
                        className={'buttonExperimente'}
                    >
                        Experimente grátis
                    </BotaoExeperimente>
                </Content1>
            ) : (
                <Content1 id="CONTENT_1">
                    <Box display={'flex'} flex={1}>
                        <DivApresentaocaoVideo>
                            <Box flex={1} maxWidth={'750px'} mr={2}>
                                <Typography
                                    variant="body1"
                                    fontSize={isSmallScreen ? 18 : 22}
                                    children={'OTIMIZE SUAS CONSULTAS COM IA'}
                                />
                                <Typography
                                    variant="h1"
                                    fontSize={isSmallScreen ? 46 : 52}
                                    fontWeight="bold"
                                    children={'Economize seu tempo no registro do prontuário do paciente.'}
                                    mb={4}
                                />
                                <Typography
                                    variant="body1"
                                    fontSize={isSmallScreen ? 16 : 20}
                                    fontWeight={400}
                                    mt={1}
                                    color={'#6f6f6f'}
                                    children={
                                        'Que tal um R1 para chamar de seu? Tenha seu assistente pessoal com Inteligência Artificial para documentação clínica automatizada.'
                                    }
                                    mb={4}
                                />
                            </Box>
                            <ContentVideo>
                                <iframe
                                    width={'100%'}
                                    height={'100%'}
                                    src="https://www.youtube.com/embed/z9yhQ1TChgQ?si=XcJbOXzvSvbUtkU1"
                                    title="YouTube video player"
                                    frameBorder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                    allowFullScreen
                                ></iframe>
                            </ContentVideo>
                        </DivApresentaocaoVideo>
                    </Box>
                    <BotaoExeperimente
                        sx={{ marginTop: '16px' }}
                        onClick={() => setOpenContato(true)}
                        className={'buttonExperimente'}
                    >
                        Experimente grátis
                    </BotaoExeperimente>
                </Content1>
            )}
            <Box pt={2} pb={2} pr={4} pl={4} flex={1} display={'flex'} flexDirection={'column'} justifyContent={'center'} mb={4}>
                <Typography
                    variant="h3"
                    mb={4}
                    children={'O copiloto perfeito para suas consultas'}
                    fontSize={30}
                    fontWeight="bold"
                    textAlign={'center'}
                />
                <Typography
                    variant="h4"
                    children={'Transforme agora mesmo a forma como você oferece assistência médical!'}
                    color={'#6f6f6f'}
                    fontWeight="bold"
                    fontSize={18}
                    textAlign={'center'}
                />
            </Box>
            <Box display={'flex'} flexWrap={'wrap'} justifyContent={'center'} mt={3} mb={3}>
                <Box display={'flex'} gap={3} maxWidth={750} flexWrap={'wrap'} justifyContent={'center'}>
                    <DivIconesApresentacao>
                        <MicIco color={lightThemeMui.palette.primary.light} style={{ width: '40px', height: '40px' }} />
                        <Typography
                            variant="subtitle2"
                            textAlign={'center'}
                            mt={2}
                            children={'Captura e compreende o contexto da conversa entre médicos e pacientes.'}
                        />
                    </DivIconesApresentacao>
                    <DivIconesApresentacao>
                        <AiIco color={lightThemeMui.palette.primary.light} style={{ width: '40px', height: '40px' }} />
                        <Typography
                            variant="subtitle2"
                            textAlign={'center'}
                            mt={2}
                            children={'Modelos de IA generativa treinados em milhares de consultas.'}
                        />
                    </DivIconesApresentacao>
                    <DivIconesApresentacao>
                        <JustIco color={lightThemeMui.palette.primary.light} style={{ width: '40px', height: '40px' }} />
                        <Typography
                            variant="subtitle2"
                            textAlign={'center'}
                            mt={2}
                            children={'Maior segurança jurídica para o médico, clínicas e hospitais.'}
                        />
                    </DivIconesApresentacao>
                    <DivIconesApresentacao>
                        <DipIco color={lightThemeMui.palette.primary.light} style={{ width: '40px', height: '40px' }} />
                        <Typography
                            variant="subtitle2"
                            textAlign={'center'}
                            mt={2}
                            children={'R1 produz em segundos o registro completo do prontuário do paciente, com excelência.'}
                        />
                    </DivIconesApresentacao>
                    <DivIconesApresentacao>
                        <CofIco color={lightThemeMui.palette.primary.light} style={{ width: '40px', height: '40px' }} />
                        <Typography
                            variant="subtitle2"
                            textAlign={'center'}
                            mt={2}
                            children={'Modelo de preço acessível e adequado à sua demanda.'}
                        />
                    </DivIconesApresentacao>
                    <DivIconesApresentacao>
                        <SmilleIco color={lightThemeMui.palette.primary.light} style={{ width: '40px', height: '40px' }} />
                        <Typography
                            variant="subtitle2"
                            textAlign={'center'}
                            mt={2}
                            children={'Consultas mais focadas e pacientes mais satisfeitos.'}
                        />
                    </DivIconesApresentacao>
                </Box>
            </Box>

            <Box
                id="CONTENT_5"
                display={'flex'}
                width={'100%'}
                flexDirection={'column'}
                justifyContent={'center'}
                alignItems={'center'}
                mt={4}
                mb={4}
            >
                <Box pt={2} pb={2} pr={4} pl={4} mb={3} maxWidth={1080} display={'flex'} flexDirection={'column'}>
                    <Typography
                        variant="h3"
                        mb={1}
                        children={'Planos e Preços'}
                        fontSize={30}
                        fontWeight="bold"
                        textAlign={'center'}
                    />
                    <Typography
                        variant="h4"
                        children={'Veja a baixo nos pacotes e planos'}
                        color={'#6f6f6f'}
                        fontWeight="bold"
                        fontSize={18}
                        textAlign={'center'}
                    />
                </Box>
                <Box
                    display={'flex'}
                    gap={'20px'}
                    flex={1}
                    width={'100%'}
                    justifyContent={'center'}
                    sx={{
                        '@media (max-width: 600px)': {
                            flexDirection: 'column',
                            gap: '20px',
                            alignItems: 'center',
                        },
                    }}
                >
                    {pacotes
                        .sort((a, b) => a.preco - b.preco)
                        .map((pacote, index) => (
                            <DivPlano>
                                <Typography
                                    variant="body1"
                                    fontSize={20}
                                    fontWeight={600}
                                    color={lightThemeMui.palette.primary.light}
                                    textAlign={'center'}
                                    mt={1}
                                    children={pacote.nome}
                                />
                                <span style={{ textAlign: 'center', marginTop: '8px' }}>
                                    Este pacote te da direito a <strong>{pacote.credito} consultas mensais*.</strong>
                                </span>

                                <Typography
                                    variant="h5"
                                    textAlign={'center'}
                                    color={lightThemeMui.palette.success.main}
                                    fontWeight={'bold'}
                                    mt={2}
                                    children={`12x de ${(pacote.preco / 100 / 12).toLocaleString('pt-br', {
                                        style: 'currency',
                                        currency: 'BRL',
                                    })}`}
                                />
                            </DivPlano>
                        ))}
                    <DivPlano onClick={() => setOpenContato(true)} style={{ cursor: 'pointer' }}>
                        <Typography
                            variant="body1"
                            fontSize={20}
                            fontWeight={600}
                            color={lightThemeMui.palette.primary.light}
                            textAlign={'center'}
                            mt={1}
                            children={'Plano Empresarial'}
                        />
                        <span style={{ textAlign: 'center', marginTop: '8px' }}>
                            Consulte nossos planos empresariais(hospitais e clinicas), com descontos especiais para empresas e
                            clínicas.
                            <b>Clique aqui para fazer contato</b>
                        </span>

                        <Typography
                            variant="h6"
                            textAlign={'center'}
                            color={lightThemeMui.palette.primary.dark}
                            fontWeight={'bold'}
                            mt={2}
                            children={'Consulte-nos'}
                        />
                    </DivPlano>
                </Box>
                <Typography
                    variant="caption"
                    textAlign={'center'}
                    color={'#6f6f6f'}
                    mt={2}
                    children={'*Um a consulta equivale a um atendimento médico com até 90 minutos de duração.'}
                />
            </Box>

            <Box
                id="CONTENT_6"
                display={'flex'}
                alignItems={'center'}
                flexDirection={'column'}
                mb={1}
                pt={2}
                pb={2}
                pr={4}
                pl={4}
                flex={1}
                gap={2}
            >
                <Box
                    display={'flex'}
                    flexDirection={'column'}
                    gap={3}
                    maxWidth={1080}
                    justifyContent={'center'}
                    alignItems={'center'}
                    mb={4}
                >
                    <Typography
                        variant="h3"
                        mb={1}
                        children={'Especialidade Médicas'}
                        fontSize={30}
                        fontWeight="bold"
                        textAlign={'center'}
                    />
                    <Typography
                        variant="h4"
                        children={'O R1 MED esta pronto para atender as mais diversas especialidades médicas.'}
                        color={'#6f6f6f'}
                        fontWeight="bold"
                        fontSize={18}
                        textAlign={'center'}
                    />
                </Box>
                <Box display={'grid'} gridTemplateColumns={'repeat(2, 1fr)'} gap={'50px'} maxWidth={600}>
                    {especialidadesMedicas.map((especialidade, index) => (
                        <Box key={`especialidadeDiv${index}`} display={'flex'} flex={1} flexDirection={'column'} gap={'20px'}>
                            <Box
                                padding={'8px'}
                                bgcolor={theme.palette.primary.main}
                                borderRadius={'50%'}
                                width={'42px'}
                                height={'42px'}
                                display={'flex'}
                                justifyContent={'center'}
                                alignItems={'center'}
                            >
                                <img
                                    src={especialidade.icon}
                                    alt={especialidade.texto}
                                    style={{ height: '100%', filter: 'invert(1)' }}
                                />
                            </Box>
                            <Box borderLeft={`5px solid ${theme.palette.primary.main}`} paddingLeft={'5px'}>
                                <Typography fontSize={'22px'} variant="body1" children={especialidade.texto} fontWeight="bold" />
                            </Box>
                            <Box display={'flex'} flexDirection={'column'} gap={1} alignItems={'flex-start'}>
                                {especialidade.especialidades.map((subEspecialidade, index) => (
                                    <Typography
                                        color={'#6e6e6e'}
                                        fontWeight={600}
                                        key={`subEspecialidade${index}`}
                                        variant="caption"
                                        children={subEspecialidade}
                                    />
                                ))}
                            </Box>
                        </Box>
                    ))}
                </Box>
            </Box>

            <Box id="CONTENT_2" display={'flex'} justifyContent={'center'} alignItems={'center'} mt={2}>
                <Box pt={2} pb={2} pr={4} pl={4} maxWidth={1080} display={'flex'}>
                    <DivTextoCalcVantagens
                        flex={1}
                        display={'flex'}
                        flexDirection={'column'}
                        maxWidth={600}
                        justifyContent={'center'}
                        mr={4}
                    >
                        <Typography
                            mb={4}
                            variant="h1"
                            fontSize={isSmallScreen ? 46 : 52}
                            fontWeight="bold"
                            children={'Calcule aqui seu potencial de faturamento usando o R1 MED.'}
                        />
                        <Typography
                            variant="h4"
                            mb={4}
                            fontSize={isSmallScreen ? 16 : 20}
                            color={'#6f6f6f'}
                            children={
                                'Descubra como a economia de tempo do R1 MED melhora a eficiência do fluxo de trabalho, ajudando você à aumentar seu rendimento, melhorando seus resultados financeiros e qualidade de vida.'
                            }
                        />
                        <Box>
                            <Button variant="contained" sx={{ textTransform: 'unset' }} onClick={() => setOpenCalculoUsoR1(true)}>
                                Simule o quanto você ganha usando o R1
                            </Button>
                        </Box>
                    </DivTextoCalcVantagens>
                    <DivImgRobozinho flex={0.4}>
                        <img src={robozinho} alt="robozinho" style={{ width: '100%', maxWidth: '300px' }} />
                    </DivImgRobozinho>
                </Box>
            </Box>
            <Box pt={2} pb={2} pr={4} pl={4} flex={1} display={'flex'} flexDirection={'column'} justifyContent={'center'}>
                <Typography
                    variant="h4"
                    children={'Resultados expressivos'}
                    color={'#6f6f6f'}
                    fontWeight="bold"
                    fontSize={18}
                    textAlign={'center'}
                />
                <Typography
                    variant="h3"
                    mb={4}
                    children={'Impactos positivos para médicos e pacientes:'}
                    fontSize={30}
                    fontWeight="bold"
                    textAlign={'center'}
                />
            </Box>
            <Box
                id="CONTENT_3"
                display={'flex'}
                justifyContent={'center'}
                alignItems={'center'}
                mb={1}
                pt={2}
                pb={2}
                pr={4}
                pl={4}
                flex={1}
            >
                <Box
                    display={'flex'}
                    gap={3}
                    maxWidth={1080}
                    flexWrap={'wrap'}
                    justifyContent={'center'}
                    alignItems={'normal'}
                    mb={2}
                >
                    <DivIconesApresentacaoVantagensPaciente>
                        <Typography
                            variant="body1"
                            fontSize={40}
                            color={lightThemeMui.palette.primary.light}
                            textAlign={'center'}
                            mt={1}
                            children={'65%'}
                        />
                        <Typography
                            variant="subtitle2"
                            textAlign={'center'}
                            mt={2}
                            children={'Redução de tempo de preenchimento do prontuário.'}
                        />
                    </DivIconesApresentacaoVantagensPaciente>
                    <DivIconesApresentacaoVantagensPaciente>
                        <Typography
                            variant="body1"
                            fontSize={40}
                            color={lightThemeMui.palette.primary.light}
                            textAlign={'center'}
                            mt={1}
                            children={'70%'}
                        />
                        <Typography
                            variant="subtitle2"
                            textAlign={'center'}
                            mt={2}
                            children={'Redução da sensação de esgotamento ao final do dia.'}
                        />
                    </DivIconesApresentacaoVantagensPaciente>
                    <DivIconesApresentacaoVantagensPaciente>
                        <Typography
                            variant="body1"
                            fontSize={40}
                            color={lightThemeMui.palette.primary.light}
                            textAlign={'center'}
                            mt={1}
                            children={'75%'}
                        />
                        <Typography
                            variant="subtitle2"
                            textAlign={'center'}
                            mt={2}
                            children={'Dos médicos referiram melhora expressiva na qualidade do prontuário.'}
                        />
                    </DivIconesApresentacaoVantagensPaciente>
                    <DivIconesApresentacaoVantagensPaciente>
                        <Typography
                            variant="body1"
                            fontSize={40}
                            color={lightThemeMui.palette.primary.light}
                            textAlign={'center'}
                            mt={1}
                            children={'80%'}
                        />
                        <Typography
                            variant="subtitle2"
                            textAlign={'center'}
                            mt={2}
                            children={'Dos pacientes referiram ter recebido maior atenção do médico durante a consulta.'}
                        />
                    </DivIconesApresentacaoVantagensPaciente>
                </Box>
            </Box>
            <Box display={'flex'} justifyContent={'center'} mt={4} mb={4}>
                <Box>
                    <BotaoExeperimente onClick={() => setOpenContato(true)}>Experimente grátis</BotaoExeperimente>
                </Box>
            </Box>
            <Box
                display={'flex'}
                justifyContent={'center'}
                flexDirection={'column'}
                alignItems={'center'}
                pt={2}
                pb={2}
                pr={4}
                pl={4}
            >
                <Box display={'flex'} justifyContent={'center'} alignItems={'center'} height={'fit-content'}>
                    <Typography
                        variant="caption"
                        sx={{ cursor: 'pointer' }}
                        children={'Contato'.toUpperCase()}
                        onClick={() => setOpenContato(true)}
                    />
                    <Divider orientation="vertical" flexItem sx={{ mr: 0.5, ml: 0.5 }} />
                    <Typography
                        variant="caption"
                        sx={{ cursor: 'pointer' }}
                        onClick={() => setOpenQuemSomos(true)}
                        children={'Quem somos'.toUpperCase()}
                    />
                    <Divider orientation="vertical" flexItem sx={{ mr: 0.5, ml: 0.5 }} />
                    <Typography variant="caption" sx={{ cursor: 'pointer' }} children={'Termos de uso'.toUpperCase()} />
                    <Divider orientation="vertical" flexItem sx={{ mr: 0.5, ml: 0.5 }} />
                    <Typography
                        variant="caption"
                        sx={{ cursor: 'pointer' }}
                        onClick={() => setOpenPoliticaPrivacidade(true)}
                        children={'Política de privacidade'.toUpperCase()}
                    />
                </Box>
                <Typography variant="caption" children={'(c) 2023 - 2024 R1 MED Tecnologia'} />
            </Box>
            <Dialog
                onClose={() => setOpenCalculoUsoR1(false)}
                open={openCalculoUsoR1}
                PaperProps={{ sx: { maxWidth: 'unset', display: 'flex', flexDirection: 'column' } }}
            >
                <TitleSimulacao position={'relative'}>
                    <Typography
                        variant="body1"
                        fontWeight={600}
                        textAlign={'center'}
                        fontSize={20}
                        color={lightThemeMui.palette.primary.main}
                    >
                        Simulação economia pelo uso do R1
                    </Typography>
                    <CloseIcon onClick={() => setOpenCalculoUsoR1(false)} />
                </TitleSimulacao>
                <DialogContent sx={{ display: 'flex', flexDirection: 'column' }}>
                    <BoxMainSimulacao>
                        <Box display={'flex'} mb={2} flexDirection={'row'} flexWrap={'wrap'} gap={'5px'}>
                            <TextField
                                type="number"
                                size="small"
                                focused
                                variant="outlined"
                                label={'Média de consultas por semana'}
                                sx={{ mb: 2, minWidth: '100px', flex: 2 }}
                                value={mediaConstultaSemana}
                                inputProps={{ min: 0 }}
                                onChange={(event) => setMediaConstultaSemana(event.target.value)}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">Consultas/Sem.</InputAdornment>,
                                }}
                            />
                            <TextField
                                type="number"
                                size="small"
                                focused
                                variant="outlined"
                                label={'Faturamento médio por consulta'}
                                sx={{ mb: 2, minWidth: '100px', flex: 1 }}
                                value={faturamentoMedioConsulta}
                                inputProps={{ min: 0 }}
                                onChange={(event) => setFaturamentoMedioConsulta(event.target.value)}
                                InputProps={{
                                    startAdornment: <InputAdornment position="start">R$</InputAdornment>,
                                }}
                            />
                            <TextField
                                type="number"
                                size="small"
                                focused
                                variant="outlined"
                                label={'Tempo médio de consulta (min)'}
                                sx={{ minWidth: '100px', width: '100%' }}
                                value={tempoMedioConsulta}
                                inputProps={{ min: 0 }}
                                onChange={(event) => setTempoMedioConsulta(event.target.value)}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">Minutos</InputAdornment>,
                                }}
                            />
                        </Box>
                        <Divider orientation="vertical" flexItem sx={{ mr: 1, ml: 1 }} />
                        <Box>
                            <Box mb={1}>
                                <Typography variant="body2" children={'Tempo médio gasto em registro de prontuário'} />
                                <Typography
                                    variant="body2"
                                    fontWeight={'bold'}
                                    children={`${formatarTempo(calcTempoMedioGastoDocumentacao() * 60)}`}
                                />
                            </Box>
                            <Divider />
                            <Box mt={1} mb={1}>
                                <Typography variant="body2" children={'Tempo médio utilizando R1.AI'} />
                                <Typography
                                    variant="body2"
                                    fontWeight={'bold'}
                                    children={`${formatarTempo(calcTempoUsoMedioR1() * 60)}`}
                                />
                            </Box>
                            <Divider />
                            <Box mt={1} mb={1}>
                                <Typography variant="body2" children={'Economia de tempo por semana com R1.AI'} />
                                <Typography
                                    variant="body2"
                                    fontWeight={'bold'}
                                    children={`${formatarTempoHoras(calcEconomiaTempoSemanaUsoR1() * 3600)}`}
                                />
                            </Box>
                            <Divider />
                            <Box
                                mt={1}
                                mb={1}
                                border={`1px solid ${lightThemeMui.palette.primary.main}`}
                                bgcolor={'#4e0e5820'}
                                padding={'10px 5px'}
                                borderRadius={'8px'}
                            >
                                <Typography
                                    variant="h6"
                                    textAlign={'center'}
                                    color={lightThemeMui.palette.primary.main}
                                    fontWeight={'400'}
                                    children={'Economia de tempo por mês!'}
                                />
                                <Typography
                                    variant="h6"
                                    color={lightThemeMui.palette.primary.main}
                                    textAlign={'center'}
                                    fontWeight={'bold'}
                                    children={`${formatarTempoHoras(calcEconomiaHorasMes() * 3600)}`}
                                />
                            </Box>
                        </Box>
                        <Divider orientation="vertical" flexItem sx={{ mr: 1, ml: 1 }} />
                        <Box>
                            <Typography
                                variant="body2"
                                children={'Qual % de tempo você quer reinvestir em novas consultas'}
                                sx={{ mb: 1 }}
                            />
                            <Box display={'flex'} gap={'10px'} alignItems={'center'}>
                                <Typography variant="subtitle2">{percentReinvestirNovasConsultas}%</Typography>
                                <IOSSlider
                                    sx={{ flex: 1 }}
                                    size="small"
                                    value={percentReinvestirNovasConsultas}
                                    aria-label="Small"
                                    valueLabelDisplay="auto"
                                    min={0}
                                    max={100}
                                    onChange={(event) => setPercentReinvestirNovasConsultas(event.target.value)}
                                />
                            </Box>
                            <Box mt={1} mb={1}>
                                <Typography variant="body2" children={'Tempo disponível para novas consultas'} />
                                <Typography
                                    fontWeight={'bold'}
                                    variant="body2"
                                    children={`${formatarTempoHoras(calcHorasReservadasNovaConsulta() * 3600)}`}
                                />
                            </Box>
                            <Divider />
                            <Box mt={1} mb={1}>
                                <Typography variant="body2" children={'Tempo para uso pessoal'} />
                                <Typography
                                    fontWeight={'bold'}
                                    variant="body2"
                                    children={`${formatarTempoHoras(calcHorasReservadasUsoPessoal() * 3600)}`}
                                />
                            </Box>
                            <Divider />
                            <Box mt={1} mb={1}>
                                <Typography variant="body2" children={'Oportunidade de aumento de consultas mensal'} />
                                <Typography
                                    fontWeight={'bold'}
                                    variant="body2"
                                    children={`${Math.round(calcAumentoConsultasMensal())} Consultas`}
                                />
                            </Box>
                            <Divider />
                            <Box
                                mt={1}
                                mb={1}
                                border={`1px solid ${lightThemeMui.palette.primary.main}`}
                                bgcolor={'#4e0e5820'}
                                padding={'10px 5px'}
                                borderRadius={'8px'}
                            >
                                <Typography
                                    variant="h6"
                                    textAlign={'center'}
                                    color={lightThemeMui.palette.primary.main}
                                    fontWeight={'400'}
                                    children={'Oportunidade de aumento de faturamento mensal!'}
                                />
                                <Typography
                                    variant="h6"
                                    fontWeight={'bold'}
                                    textAlign={'center'}
                                    color={lightThemeMui.palette.primary.main}
                                    fontSize={20}
                                    children={`${parseInt(calcAumentoFaturamentoMensal()).toLocaleString('pt-br', {
                                        style: 'currency',
                                        currency: 'BRL',
                                    })}`}
                                />
                            </Box>
                        </Box>
                    </BoxMainSimulacao>
                </DialogContent>
            </Dialog>
            <Dialog onClose={() => setOpenQuemSomos(false)} open={openQuemSomos} PaperProps={{ sx: { maxWidth: 'unset' } }}>
                <TitleSimulacao position={'relative'}>
                    <Typography variant="body1" textAlign={'center'} fontSize={20}>
                        Quem somos
                    </Typography>
                    <CloseIcon onClick={() => setOpenQuemSomos(false)} />
                </TitleSimulacao>
                <DialogContent>
                    <Typography variant="body1" mb={2} fontWeight={'bolder'}>
                        R1MED: transformando consultas médicas através de inteligência artificial
                    </Typography>
                    <Typography variant="body1" mb={2}>
                        Bem-vindo ao R1MED, uma iniciativa revolucionária desenvolvida pelos médicos Carlos Lopes, Pedro Baldaque
                        e Ronaldo Carneiro, com o objetivo de transformar a maneira como consultas médicas são realizadas no
                        Brasil.
                    </Typography>
                    <Typography variant="body1" mb={2}>
                        Dr Carlos Lopes é médico nutrólogo, com extensão em tratamento de obesidade pela Universidade de Harvard
                        (EUA), com mais de 18 anos de experiência na área de tecnologia em saúde, atuando desde 2005 como CEO de
                        uma das maiores empresas do Brasil no segmento de prontuário eletrônico.
                    </Typography>
                    <Typography variant="body1" mb={2}>
                        Dr Pedro Baldaque é médico gastroenterologista com mestrado em medicina pelo Departamento de Clínica
                        Médica do HUCFF-UFRJ e coordenador dos cursos de pós-graduação em gastroenterologia da Universidade
                        Castelo Branco, Instituto Carlos Chagas e Centro Universitário Celso Lisboa. É chefe do serviço de
                        Gastroenterologia do Hospital da Gamboa e CEO do Instituto de Gastroenterologia do Rio de Janeiro, com
                        larga experiência no desenvolvimento de novos projetos na área médica.
                    </Typography>
                    <Typography variant="body1" mb={2}>
                        Dr Ronaldo Carneiro é gastroenterologista, mestre em Ciências Médicas pela UERJ-PGCM), professor e
                        coordenador adjunto de Pós-Graduação em Gastroenterologia, ex-Coordenador Académico de cursos de Medicina
                        no Rio de Janeiro/RJ e em Salvador/BA, membro do Departamento médico-científico na indústria farmacêutica
                        e grande entusiasta do impacto transformador da tecnologia na Medicina contemporânea.
                    </Typography>
                    <Typography variant="body1" mb={2} fontWeight={'bolder'}>
                        Nossa inspiração: enfrentando os desafios na Saúde brasileira
                    </Typography>
                    <Typography variant="body1" mb={2}>
                        O grande motivador para criação da R1MED foi a observação do quanto rotinas burocráticas e de documentação
                        médica ocupam tempo precioso do médico, que já convive com burnout e jornadas de trabalho intensas.
                    </Typography>
                    <Typography variant="body1" mb={2}>
                        {' '}
                        Além disso o aumento significativo nos processos judiciais relacionados à saúde no Brasil também
                        demonstrou o quanto a falta de tempo e a documentação clínica ineficiente podem causar problemas na esfera
                        cível e criminal. Questões como demora no atendimento, falta de empatia dos profissionais de saúde e o
                        esgotamento emocional dos mesmos impulsionaram a nossa busca por soluções inovadoras.
                    </Typography>
                    <Typography variant="body1" mb={2} fontWeight={'bolder'}>
                        R1MED: uma Ferramenta de inteligência artificial para transformar consultas médicas
                    </Typography>
                    <Typography variant="body1" mb={2}>
                        Desenvolvemos o R1MED, um aplicativo inovador que combina inteligência artificial conversacional com as
                        mais avançadas tecnologias de inteligência artificial generativa. Disponível desde novembro para clínicas
                        selecionadas em fase de testes, a ferramenta está disponível para médicos, clínicas e hospitais de todo
                        Brasil a partir de janeiro de 2024.
                    </Typography>
                    <Typography variant="body1" mb={2} fontWeight={'bolder'}>
                        Uma revolução no atendimento médico: principais características do R1MED
                    </Typography>
                    <Typography variant="body1" mb={2}>
                        Automatização da documentação do prontuário médico: o R1MED é um assistente de inteligência artificial que
                        automatiza a documentação clínica, permitindo que os médicos se dediquem mais ao paciente e otimizem o
                        tempo gasto com tarefas burocráticas.
                    </Typography>
                    <Typography variant="body1" mb={2}>
                        Eficiência operacional: desenvolvido para acelerar o atendimento, o R1MED reduz significativamente o tempo
                        de preenchimento do prontuário, alinhando-se com as demandas da área médica.
                    </Typography>
                    <Typography variant="body1" mb={2}>
                        Impacto na qualidade do atendimento: nosso objetivo é reduzir em até 65% o tempo dedicado à documentação,
                        melhorando a qualidade da atenção ao paciente durante a consulta e combatendo o esgotamento dos
                        profissionais de saúde.
                    </Typography>
                    <Typography variant="body1" mb={2} fontWeight={'bolder'}>
                        Compromisso com a segurança e padronização: impacto legal e ambiental
                    </Typography>
                    <Typography variant="body1" mb={2}>
                        Além de proporcionar maior segurança jurídica, padronização na documentação do prontuário e aprimorar o
                        ambiente de trabalho dos profissionais de saúde, o R1MED contribui para a melhoria expressiva na qualidade
                        do prontuário, crucial para a continuidade do tratamento e prevenção de processos judiciais por erro
                        médico.
                    </Typography>
                    <Typography variant="body1" mb={2} fontWeight={'bolder'}>
                        Flexibilidade para diferentes cenários: treinamento personalizado
                    </Typography>
                    <Typography variant="body1" mb={2}>
                        Os modelos de inteligência artificial do R1 podem ser treinados para diversos cenários, abrangendo desde
                        atendimento ambulatorial até situações emergenciais e cuidados domiciliares.
                    </Typography>
                    <Typography variant="body1" mb={2}>
                        Junte-se a nós na revolução do atendimento médico. Com o R1MED, estamos comprometidos em transformar a
                        experiência em saúde, trazendo eficiência, empatia e qualidade para o centro de cada consulta.
                    </Typography>
                </DialogContent>
            </Dialog>
            <Dialog
                onClose={() => setOpenPoliticaPrivacidade(false)}
                open={openPoliticaPrivacidade}
                PaperProps={{ sx: { maxWidth: 'unset' } }}
            >
                <TitleSimulacao position={'relative'}>
                    <Typography variant="body1" textAlign={'center'} fontSize={20}>
                        Política de privacidade
                    </Typography>
                    <CloseIcon onClick={() => setOpenPoliticaPrivacidade(false)} />
                </TitleSimulacao>
                <DialogContent>
                    <h2>
                        <strong>Sua privacidade é importante para nós!</strong>
                    </h2>
                    Este site é gerido e administrado pela MEDX TECNOLOGIA S/A, que desenvolve um trabalho contínuo de garantia da
                    proteção dos dados pessoais fornecidos pelo usuário, tratando a informação nos termos previstos na lei
                    brasileira LGPD (Lei Geral de Proteção de Dados) nº13.709/2018, assim como a lei europeia GDPR (General Data
                    Protection Regulation) (EU) 2016/679 de 27 de abril de 2016. Também aplicamos as melhores práticas de
                    cibersegurança e proteção de dados, descritas na ISO/IEC 27001:2013, ISO/IEC 27701:2019, ENISA, NIST CSF, Cert
                    RMM, entre outras boas práticas de mercado. Esta política de privacidade descreve: 1. Quem é o responsável
                    pelos meus dados pessoais? 2. Quais dados pessoais são tratados pela MEDX? E porquê? 3. Por quanto tempo os
                    meus dados pessoais serão conservados? 4. Como conservam os meus dados pessoais? 5. A que entidades podem ser
                    enviados os meus dados pessoais? 6. Quais são os meus direitos? 7. Como posso conhecer quaisquer alterações a
                    esta política de privacidade?
                    <h5>
                        <strong>1. Quem é o responsável pelos meus dados pessoais?</strong>
                    </h5>
                    Os seus dados serão tratados por <strong>MEDX TECNOLOGIA S/A CNPJ: 25.402.501/0001-25</strong>, Av João Cabral
                    de Melo Neto, 850 bloco 3 grupo 705 – Barra da Tijuca – Rio de Janeiro – RJ Poderá contatar-nos e ao nosso
                    responsável pela proteção dos seus dados através do e-mail dpo@medx.med.br
                    <h5>
                        <strong>2. Quais dados pessoais são tratados pela MEDX?</strong>
                    </h5>
                    <table>
                        <tbody>
                            <tr>
                                <td>
                                    <b>Origem de coleta</b>
                                </td>
                                <td>
                                    <b>Finalidade</b>
                                </td>
                                <td>
                                    <b>Fundamento Jurídico</b>
                                </td>
                                <td>
                                    <b>Dados pessoais coletados</b>
                                </td>
                                <td>
                                    <b>Prazo de conservação </b>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <span style={{ fontWeight: 400 }}>http://info.wp36f10.kinghost.net</span>
                                </td>
                                <td>
                                    <span style={{ fontWeight: 400 }}>Comunicações</span>
                                </td>
                                <td>
                                    <span style={{ fontWeight: 400 }}> Consentimento</span>
                                </td>
                                <td>
                                    <span style={{ fontWeight: 400 }}>Nome</span>

                                    <span style={{ fontWeight: 400 }}>E-mail</span>

                                    <span style={{ fontWeight: 400 }}>Telefone</span>
                                </td>
                                <td>
                                    <span style={{ fontWeight: 400 }}>12 meses</span>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <span style={{ fontWeight: 400 }}>Redes sociais</span>
                                </td>
                                <td>
                                    <span style={{ fontWeight: 400 }}>Comunicações </span>
                                </td>
                                <td>
                                    <span style={{ fontWeight: 400 }}> Consentimento</span>
                                </td>
                                <td>
                                    <span style={{ fontWeight: 400 }}>Nome</span>

                                    <span style={{ fontWeight: 400 }}>E-mail</span>

                                    <span style={{ fontWeight: 400 }}>Telefone</span>
                                </td>
                                <td>
                                    <span style={{ fontWeight: 400 }}>12 meses</span>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <span style={{ fontWeight: 400 }}>WhatsApp</span>
                                </td>
                                <td>
                                    <span style={{ fontWeight: 400 }}>Comunicações</span>
                                </td>
                                <td>
                                    <span style={{ fontWeight: 400 }}> Consentimento</span>
                                </td>
                                <td>
                                    <span style={{ fontWeight: 400 }}>Nome</span>

                                    <span style={{ fontWeight: 400 }}>E-mail</span>

                                    <span style={{ fontWeight: 400 }}>Telefone</span>
                                </td>
                                <td>
                                    <span style={{ fontWeight: 400 }}>12 meses</span>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <span style={{ fontWeight: 400 }}>http://info.wp36f10.kinghost.net</span>
                                </td>
                                <td>
                                    <span style={{ fontWeight: 400 }}>Análise de informações de navegação</span>
                                </td>
                                <td>
                                    <span style={{ fontWeight: 400 }}>Consentimento (quando você aceita cookies)</span>
                                </td>
                                <td>
                                    <span style={{ fontWeight: 400 }}>Endereço IP</span>
                                </td>
                                <td>
                                    <span style={{ fontWeight: 400 }}>12 meses</span>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <span style={{ fontWeight: 400 }}>http://info.wp36f10.kinghost.net</span>
                                </td>
                                <td>
                                    <span style={{ fontWeight: 400 }}>Adesão de assinatura</span>
                                </td>
                                <td>
                                    <span style={{ fontWeight: 400 }}>Execução de um contrato </span>
                                </td>
                                <td>
                                    <span style={{ fontWeight: 400 }}>Nome</span>

                                    <span style={{ fontWeight: 400 }}>E-mail</span>

                                    <span style={{ fontWeight: 400 }}>Telefone</span>

                                    <span style={{ fontWeight: 400 }}>Celular</span>

                                    <span style={{ fontWeight: 400 }}>CPF / CNPJ</span>
                                </td>
                                <td>
                                    <span style={{ fontWeight: 400 }}>12 meses após término do contrato</span>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <span style={{ fontWeight: 400 }}>Plataforma MEDX</span>
                                </td>
                                <td>
                                    <span style={{ fontWeight: 400 }}>Prestação do serviço entre médico e paciente</span>
                                </td>
                                <td>
                                    <span style={{ fontWeight: 400 }}>Execução de um contrato</span>
                                </td>
                                <td>
                                    <span style={{ fontWeight: 400 }}>Nome</span>

                                    <span style={{ fontWeight: 400 }}>E-mail</span>

                                    <span style={{ fontWeight: 400 }}>Telefone</span>

                                    <span style={{ fontWeight: 400 }}>Celular</span>

                                    <span style={{ fontWeight: 400 }}>CPF / CNPJ e</span>

                                    <span style={{ fontWeight: 400 }}>Dados de Saúde</span>
                                </td>
                                <td>
                                    <span style={{ fontWeight: 400 }}>20 anos</span>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <span style={{ fontWeight: 400 }}>https://docflix.live</span>
                                </td>
                                <td>
                                    <span style={{ fontWeight: 400 }}>Adesão de Assinatura</span>

                                    <span style={{ fontWeight: 400 }}>(acesso pago)</span>
                                </td>
                                <td>
                                    <span style={{ fontWeight: 400 }}>Execução de um contrato</span>
                                </td>
                                <td>
                                    <span style={{ fontWeight: 400 }}>E-mail</span>

                                    <span style={{ fontWeight: 400 }}>Nome</span>

                                    <span style={{ fontWeight: 400 }}>CPF ou CNPJ</span>

                                    <span style={{ fontWeight: 400 }}>Endereço</span>

                                    <span style={{ fontWeight: 400 }}>CEP</span>
                                </td>
                                <td>
                                    <span style={{ fontWeight: 400 }}>12 meses após término do contrato</span>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <span style={{ fontWeight: 400 }}>https://docflix.live</span>
                                </td>
                                <td>
                                    <span style={{ fontWeight: 400 }}>Adesão de Assinatura</span>

                                    <span style={{ fontWeight: 400 }}>(acesso gratuito)</span>
                                </td>
                                <td>
                                    <span style={{ fontWeight: 400 }}>Consentimento</span>
                                </td>
                                <td>
                                    <span style={{ fontWeight: 400 }}>E-mail</span>

                                    <span style={{ fontWeight: 400 }}>Nome</span>
                                </td>
                                <td>
                                    <span style={{ fontWeight: 400 }}>12 meses</span>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <span style={{ fontWeight: 400 }}>https://medx.social</span>
                                </td>
                                <td>
                                    <span style={{ fontWeight: 400 }}>Adesão de assinatura (trial)</span>
                                </td>
                                <td>
                                    <span style={{ fontWeight: 400 }}>Execução de um contrato</span>
                                </td>
                                <td>
                                    <span style={{ fontWeight: 400 }}>E-mail</span>

                                    <span style={{ fontWeight: 400 }}>Nome</span>
                                </td>
                                <td>
                                    <span style={{ fontWeight: 400 }}>12 meses após término do contrato</span>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <span style={{ fontWeight: 400 }}>https://medx.social</span>
                                </td>
                                <td>
                                    <span style={{ fontWeight: 400 }}>Adesão de assinatura (acesso pago)</span>
                                </td>
                                <td>
                                    <span style={{ fontWeight: 400 }}>Execução de um contrato</span>
                                </td>
                                <td>
                                    <span style={{ fontWeight: 400 }}>E-mail</span>

                                    <span style={{ fontWeight: 400 }}>Nome</span>

                                    <span style={{ fontWeight: 400 }}>CPF ou CNPJ</span>

                                    <span style={{ fontWeight: 400 }}>Endereço</span>

                                    <span style={{ fontWeight: 400 }}>CEP</span>
                                </td>
                                <td>
                                    <span style={{ fontWeight: 400 }}>12 meses após término do contrato</span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <h5>
                        <strong>3. Por quanto tempo meus dados pessoais serão conservados?</strong>
                    </h5>
                    <span style={{ fontWeight: 400 }}>
                        O período durante o qual os seus dados pessoais são armazenados e conservados varia de acordo com a
                        finalidade para a qual os dados foram recolhidos e estão definidos na tabela do item 2.
                    </span>
                    <h5>
                        <strong>4. Como conservam os meus dados pessoais?</strong>
                    </h5>
                    <span style={{ fontWeight: 400 }}>
                        Nossa obrigação é manter os seus dados seguros, e para isso aplicamos medidas de segurança apropriadas
                        para assegurar a proteção dos seus dados pessoais e impedir o acesso de pessoas não autorizadas. Estamos
                        em conformidade com a LGPD (Lei Geral de Proteção de Dados - Brasil) e GDPR (General Data Protection
                        Regulation - EU) além de aplicamos diversas práticas de cibersegurança e proteção de dados, tais como:
                        gestão de vulnerabilidades,  testes de invasão em nossos sistemas e tecnologias, conscientização dos
                        colaboradores com relação ao tema cibersegurança e proteção de dados, gestão e resposta a incidentes de
                        segurança, avaliação de impacto na privacidade (PIA), revisão e melhorias constantes nos processos
                        internos, entre outras práticas.
                    </span>
                    <h5>
                        <strong>5. A que entidades podem ser enviados os meus dados pessoais?</strong>
                    </h5>
                    <span style={{ fontWeight: 400 }}>
                        Podemos divulgar suas informações pessoais caso sejamos obrigados pela lei para fazê-lo. No entanto, o
                        tratamento dos seus dados pessoais poderá ser efetuado por prestadores de serviços, mas pediremos o seu
                        consentimento para tal.
                    </span>
                    <span style={{ fontWeight: 400 }}>
                        Como a MEDX possui clientes em diversos países diferentes, tratamos os dados pessoais na sede localizada
                        no Brasil, assim como através de prestadores de serviço com a sede localizada dentro e fora do Brasil, o
                        que pode implicar um compartilhamento internacional dos seus dados. Tomaremos as salvaguardas necessárias
                        para garantir o tratamento seguro dos seus dados, através de contratos celebrados contendo cláusulas
                        contratuais e aplicando práticas de cibersegurança para garantir a segurança dos seus dados durante todo o
                        ciclo de vida.
                    </span>
                    <h5>
                        <strong>6. Quais são os meus direitos?</strong>
                    </h5>
                    <span style={{ fontWeight: 400 }}>
                        Como titular dos dados, você poderá exercer os seguintes direitos, nos limites legalmente estabelecidos:
                    </span>
                    <ul>
                        <li>
                            <b>Confirmação e acesso:</b>
                            <span style={{ fontWeight: 400 }}>
                                {' '}
                                Permite que você possa verificar se a MEDX trata dados pessoais seus e, em caso positivo,
                                solicitar uma cópia dos dados pessoais que nós temos sobre você.
                            </span>
                        </li>
                        <li>
                            <b>Correção:</b>
                            <span style={{ fontWeight: 400 }}>
                                {' '}
                                Permite que você solicite a correção dos seus dados pessoais incompletos, inexatos ou
                                desatualizados.
                            </span>
                        </li>
                        <li aria-level="1">
                            <b>Anonimização, bloqueio ou eliminação: </b>
                            <span style={{ fontWeight: 400 }}>
                                Permite que você nos peça: (a) anonimizar seus dados, de forma a que eles não possam ser mais
                                relacionados a você e, portanto, deixem de ser dados pessoais; (b) bloquear seus dados,
                                suspendendo temporariamente a possibilidade de tratarmos; e (c) eliminar seus dados, caso em que
                                apagaremos todos seus dados sem possibilidade de reversão, salvo os casos previstos em lei.
                            </span>
                        </li>
                        <li aria-level="1">
                            <b>Portabilidade: </b>
                            <span style={{ fontWeight: 400 }}>
                                Você tem o direito de solicitar, mediante requisição expressa, que a MEDX, forneça a você, ou a
                                terceiros que você escolher, os seus dados pessoais em formato estruturado ou interoperável, para
                                transferência a outro fornecedor, desde que não viole a propriedade intelectual ou segredo de
                                negócio da empresa. Da mesma forma, você pode pedir que outras empresas enviem à MEDX seus dados
                                pessoais para facilitar a contratação de nossos serviços.
                            </span>
                        </li>
                        <li aria-level="1">
                            <b>Informação sobre compartilhamento: </b>
                            <span style={{ fontWeight: 400 }}>
                                Você tem o direito de saber as entidades com as quais a MEDX realiza o uso compartilhado de dados.
                                Manteremos no item 5. “A que entidades podem ser enviados meus dados pessoais?” desta política, a
                                indicação das modalidades e atividades que podem envolver compartilhamento de dados com terceiros.
                                Em todo o caso, se você tiver dúvidas ou quiser maiores detalhes, você tem o direito de nos
                                solicitar essas informações.
                            </span>
                        </li>
                        <li aria-level="1">
                            <b>Informação sobre a possibilidade de não consentir: </b>
                            <span style={{ fontWeight: 400 }}>
                                Permite que você tenha informações claras e completas sobre a possibilidade e as consequências de
                                não fornecer consentimento. O seu consentimento, quando necessário, deve ser livre e informado.
                                Portanto, sempre que pedirmos seu consentimento você será livre para negá-lo. Ainda que nesses
                                casos tenhamos que limitar nossos serviços.
                            </span>
                        </li>
                        <li aria-level="1">
                            <b>Revogação do consentimento: </b>
                            <span style={{ fontWeight: 400 }}>
                                Você tem o direito de retirar o seu consentimento em relação às atividades de tratamento que se
                                baseiam nessa base legal. No entanto, isso não afetará a legalidade de qualquer tratamento
                                realizado anteriormente. Se você retirar o seu consentimento, talvez não possamos fornecer
                                determinados serviços e experiências, mas iremos avisá-lo quando isso ocorrer.
                            </span>
                        </li>
                        <li aria-level="1">
                            <b>Oposição: </b>
                            <span style={{ fontWeight: 400 }}>
                                A lei autoriza o Tratamento de Dados Pessoais mesmo sem o seu consentimento ou um contrato
                                conosco. Nesse caso, é preciso demonstrar que há motivos legítimos para tratar seus dados, como
                                por exemplo, prevenir fraudes ou melhorar nossa comunicação com você. Caso você não concorde com
                                esse Tratamento, poderá se opor a ele, solicitando a interrupção.
                            </span>
                        </li>
                    </ul>
                    <span style={{ fontWeight: 400 }}>
                        Para exercer estes direitos, poderá enviar um pedido por escrito para o contato indicado na seção “
                    </span>
                    <i>
                        <span style={{ fontWeight: 400 }}>1. Quem é o responsável pelos meus dados pessoais?</span>
                    </i>
                    <span style={{ fontWeight: 400 }}>
                        ” e deverá indicar no seu pedido o seu nome e o direito que quer exercer.
                    </span>
                    <span style={{ fontWeight: 400 }}>
                        Se considerar que foi cometida alguma violação da legislação em matéria de proteção de dados em vigor no
                        que respeita ao tratamento dos seus dados pessoais.
                    </span>
                    <h5>
                        <strong>7. Como posso conhecer quaisquer alterações a esta política de privacidade?</strong>
                    </h5>
                    <span style={{ fontWeight: 400 }}>
                        Como buscamos sempre melhorar nossos Serviços, esta Política de Privacidade pode sofrer atualizações para
                        refletir as melhorias realizadas. Desta forma, recomendamos a visita periódica a esta página para que você
                        tenha conhecimento sobre as modificações efetuadas.
                    </span>
                    <span style={{ fontWeight: 400 }}>
                        Reservamos o direito de modificar essa Política de Privacidade a qualquer momento. Alterações e
                        esclarecimentos vão surtir efeito imediatamente após sua publicação no site.
                    </span>
                    Data: 08/11/2023
                </DialogContent>
            </Dialog>
            {/* <Dialog onClose={() => setOpenContato(false)} open={openContato} PaperProps={{ sx: { maxWidth: 'unset' } }}>
                <TitleSimulacao position={'relative'}>
                    <Typography variant="body1" textAlign={'center'} fontSize={20}>
                        Contato
                    </Typography>
                    <CloseIcon onClick={() => setOpenContato(false)} />
                </TitleSimulacao>
                <DialogContent>
                    <Box
                        component={'form'}
                        p={2}
                        display={'flex'}
                        flexDirection={'column'}
                        sx={{ minWidth: 500 }}
                        onSubmit={(event) => EnviarContato(event)}
                    >
                        <TextField
                            value={contatoInfo.Nome}
                            onChange={({ target }) => setContatoInfo({ ...contatoInfo, Nome: target.value })}
                            sx={{ mb: 3 }}
                            variant="outlined"
                            size="small"
                            focused
                            label={'Nome'}
                            placeholder="Informe seu nome."
                            required
                        />
                        <TextField
                            sx={{ mb: 3 }}
                            value={contatoInfo.Celular}
                            onChange={({ target }) => setContatoInfo({ ...contatoInfo, Celular: target.value })}
                            variant="outlined"
                            size="small"
                            focused
                            label={'Telefone'}
                            placeholder="Informe um telefone para contato."
                            required
                            title="ex: 21988887777"
                            inputProps={{
                                maxLength: 20,
                                'x-autocompletetype': 'tel',
                                pattern: '^((1[1-9])|([2-9][0-9]))((3[0-9]{3}[0-9]{4})|(9[0-9]{3}[0-9]{5}))$',
                                autoComplete: 'tel',
                            }}
                        />
                        <TextField
                            value={contatoInfo.Mensagem}
                            onChange={({ target }) => setContatoInfo({ ...contatoInfo, Mensagem: target.value })}
                            sx={{ mb: 3 }}
                            variant="outlined"
                            size="small"
                            focused
                            label={'Mensagem'}
                            multiline
                            rows={4}
                            placeholder="Digite aqui sua mensagem."
                            required
                        />
                        <Button variant="contained" type="submit">
                            Enviar
                        </Button>
                    </Box>
                </DialogContent>
            </Dialog> */}
            <ContatoCompra close={() => setOpenContato(false)} isOpen={openContato} />
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
                onClick={() => setLoading(false)}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </BoxMainApresentacao>
    );
}

export const especialidadesMedicas = [
    {
        texto: 'Especialidades Clínicas',
        icon: iconeSaude,
        especialidades: [
            'Clínica médica',
            'Cardiologia',
            'Gastroenterologia',
            'Dermatologia',
            'Endocrinologia',
            'Ortopedia',
            'entre outros...',
        ],
    },
    {
        texto: 'Modelos específicos clínica',
        icon: iconeFarmacia,
        especialidades: ['Ginecologia e Obstetrícia', 'Pediatria', 'Oftalmologia', 'Nutrologia', 'Medicina do Esporte'],
    },
    {
        texto: 'Exames de Imagem',
        icon: iconeRaioX,
        especialidades: ['Ultrassom', 'Ecocardiograma', 'entre outros'],
    },

    {
        texto: 'Emergência e CTI',
        icon: iconeAmbulancia,
        especialidades: ['Admissão', 'Relato de internação', 'Admissão', 'Evolução diurna e noturna', 'Resumo do Round'],
    },
    {
        texto: 'Especialidades Cirúrgicas',
        icon: iconeCirurgiaPlastica,
        especialidades: ['Todas as especialidades cirúrgicas.'],
    },
];
