import React, { useEffect, useMemo, useRef, useState } from 'react';

import { AssistenteStyle, Container } from './ResumoViewer.styles';
import { json, useOutletContext, useParams } from 'react-router-dom';
import { Box, Button, Typography, useTheme } from '@mui/material';
import { updateResumo } from '../../../services/resumo';
import { toast } from 'react-toastify';
import { set } from 'react-ga';
import { getAtendimentoById } from '../../../services/atendimento';
import ReactQuill from 'react-quill';

function ResumoViewer() {
    const { atendimento, setAtendimento, tiposResumos } = useOutletContext();
    const theme = useTheme();
    const params = useParams();
    const timeout = useRef(null);

    const [blocosResumos, setBlocosResumos] = useState([]);
    const [htmlResumo, setHtmlResumo] = useState('');

    useEffect(() => {
        atualizaAtendimento(params.id);
    }, [params.id, params.idResumo]);

    const ultimoResumo = useMemo(() => {
        if (!atendimento) return null;
        const listaOrdenada = atendimento?.resumos?.sort((a, b) => new Date(b.dataCriacao) - new Date(a.dataCriacao));
        return listaOrdenada?.length > 0 ? listaOrdenada[0] : null;
    }, [atendimento]);

    const tipoResumoSelecionado = useMemo(() => {
        if (ultimoResumo === null) return null;
        const resumoSelecionado = ultimoResumo.tipo;
        return tiposResumos.find((tipo) => tipo.value === resumoSelecionado);
    }, [tiposResumos, ultimoResumo]);

    useEffect(() => {
        if (ultimoResumo) {
            try {
                const jsonResumo = JSON.parse(ultimoResumo.json);
                setBlocosResumos(jsonResumo?.informacoes);
            } catch (e) {
                console.error(e);
            }
            setHtmlResumo(ultimoResumo.texto);
        }
    }, [ultimoResumo]);

    useEffect(() => {
        console.log(atendimento, blocosResumos);
    }, [atendimento]);

    function copiarTexto(texto) {
        navigator.clipboard.writeText(texto).then(
            function () {
                toast.success('Texto copiado');
            },
            function (err) {
                toast.error('Erro ao copiar texto');
            },
        );
    }

    function save(texto, index) {
        clearTimeout(timeout.current);
        timeout.current = setTimeout(function () {
            setBlocosResumos((prev) => {
                const newBlocos = [...prev];
                newBlocos[index].texto = texto;
                updateResumoRequest(ultimoResumo, newBlocos);
                return newBlocos;
            });
        }, 2000);
    }

    // function saveTexto() {
    //     clearTimeout(timeout.current);
    //     timeout.current = setTimeout(function () {
    //         updateRequest(ultimoResumo, blocosResumos);
    //     }, 2000);
    // }

    async function updateResumoRequest(resumo, blocos) {
        try {
            console.log('c% Resumo atualizando', 'color: #f00');
            const resumoResponse = await updateResumo({
                ...resumo,
                json: blocos.length > 0 ? JSON.stringify({ informacoes: blocos }) : '',
            });
            console.log('Resumo atualizado', resumoResponse);
        } catch (error) {
            toast.error('Erro ao salvar resumo');
            console.error(error);
        }
    }

    function geraTextoResumo(blocosResumos) {
        let texto = '';
        blocosResumos.forEach((bloco) => {
            texto += `${bloco.titulo}\n\n${bloco.texto}\n\n\n`;
        });
        return texto;
    }

    async function atualizaAtendimento(idAtendimento) {
        const atendimentoAtualizado = await getAtendimentoById(idAtendimento);
        setAtendimento(atendimentoAtualizado);
    }

    if (!ultimoResumo)
        return (
            <Container>
                <Typography variant="h6">Nenhum resumo gerado</Typography>
            </Container>
        );

    return (
        <Container>
            <Box
                sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    gap: 0.5,
                    borderRadius: '4px',
                    position: 'relative',
                    height: '40px',
                }}
            >
                <Typography
                    variant="body2"
                    textAlign={'center'}
                    fontWeight={600}
                    textTransform={'uppercase'}
                    width={'100%'}
                    fontSize={'1rem'}
                >
                    {tipoResumoSelecionado?.name}
                </Typography>
                <Button
                    variant="contained"
                    size="small"
                    color="success"
                    sx={{
                        fontSize: '8px',
                        position: 'absolute',
                        right: '10px',
                        textTransform: 'uppercase',
                        letterSpacing: '1px',
                    }}
                    onClick={() => copiarTexto(geraTextoResumo(blocosResumos))}
                >
                    Copiar Tudo
                </Button>
            </Box>

            {blocosResumos ? (
                <Box flex={1} sx={{ overflowY: 'auto', overflowX: 'hidden' }}>
                    {blocosResumos.map((bloco, index) => (
                        <AssistenteStyle key={`${index}-${bloco.titulo}`}>
                            <div className="containerTituloBloco">
                                <h3 style={{ color: theme.palette.primary.light }}>{bloco.titulo}</h3>
                                <Button
                                    variant="contained"
                                    size="small"
                                    sx={{ fontSize: '8px', textTransform: 'uppercase', letterSpacing: '1px' }}
                                    onClick={() => {
                                        const texto = `${bloco.titulo}\n \n${bloco.texto}`;
                                        copiarTexto(texto);
                                    }}
                                >
                                    Copiar Bloco
                                </Button>
                            </div>
                            <div className="containerTextArea">
                                <div
                                    contenteditable="true"
                                    onKeyDown={(e) => {
                                        save(e.target.innerText, index);
                                    }}
                                >
                                    {bloco.texto}
                                </div>
                            </div>
                        </AssistenteStyle>
                    ))}
                </Box>
            ) : (
                <Box flex={1} sx={{ overfloY: 'auto', overflowX: 'hidden' }}>
                    <ReactQuill
                        value={htmlResumo}
                        onChange={(e) => {
                            setHtmlResumo(e);
                        }}
                    />
                </Box>
            )}
        </Container>
    );
}

export default ResumoViewer;
