import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex-direction: row;
    border: 2px solid ${(props) => props.color};
    border-radius: 4px;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    background-color: ${(props) => props.color}91;
    color: ${(props) => props.color};
    box-shadow: 0px 0px 5px 0px ${(props) => props.color};
    position: absolute;
    bottom: 10px;
    backdrop-filter: blur(5px);
`;

export const VolumeIndicador = styled.div`
    width: 14px;
    height: 6px;
    border-radius: 2px;
    margin-right: 5px;
    background-color: ${(props) => (props.ativo ? props.cor : '#a9a9a9')};
    box-shadow: ${(props) => (props.ativo ? `0px 0px 3px 0px ${props.cor}` : 'none')};
    transition: background-color 0.3s, box-shadow 0.3s;
`;
