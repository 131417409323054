import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    overflow-y: hidden;
`;

export const ContainerAssistentes = styled.div`
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    align-items: center;
`;

export const AssistenteStyle = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    font-family: 'Roboto', sans-serif;
    max-width: 800px;

    > h3 {
        padding: 10px;
        margin-bottom: 5px;
        font-size: 20px;
        font-style: italic;
        text-transform: capitalize;
    }

    > article {
        padding: 0px 5px 10px 40px;
        line-height: 1.5;
        font-weight: 400;
        font-size: 16px;
        word-spacing: 1px;
        letter-spacing: 0.5px;
        text-shadow: 1px 1px 2px rgba(255, 255, 255, 0.1);

        > h1 {
            font-size: 18px;
        }

        > h2 {
            font-size: 16px;
        }

        > p {
            font-size: 14px;
        }
    }

    > .div-textarea-resumo {
        flex: 1;
        display: flex;
        overflow-x: hidden;
        width: 100%;
    }

    > .div-textarea-resumo > textarea {
        font-family: 'Roboto', sans-serif;
        width: 100%;
        background-color: transparent;
        border: none;
        color: #000;
        resize: none;

        padding: 0px 5px 10px 40px;
        line-height: 1.5;
        font-weight: 400;
        font-size: 16px;
        word-spacing: 1px;
        letter-spacing: 0.5px;
        text-shadow: 1px 1px 2px rgba(255, 255, 255, 0.1);
    }
`;
