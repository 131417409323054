import styled from 'styled-components';

export const ContainerAudioCapture = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    border: 2px solid;
    border-radius: 4px;
    padding: 10px;
    overflow-y: hidden;
    position: relative;
    align-items: center;
`;
