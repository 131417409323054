import React, { useEffect, useState } from 'react';
import { Dialog, DialogTitle, List, ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import { SolicitaPermissaoMicrofone } from '../../../../helpers/Mic';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
// import { Container } from './styles';

function SelectMicDialog({ onClose, open, onSelected, selectedValue }) {
    const [microfones, setMicrofones] = useState(null);
    useEffect(() => {
        if (open)
            SolicitaPermissaoMicrofone().then((mics) => {
                setMicrofones(mics);
            });
    }, [open]);
    return (
        <Dialog onClose={onClose} open={open}>
            <DialogTitle textAlign={'center'}>Selecione qual disposivo de microfone deseja utilizar:</DialogTitle>
            {microfones === null && <DialogTitle textAlign={'center'}>Carregano microfones disponiveis.</DialogTitle>}
            {microfones?.length === 0 && <DialogTitle textAlign={'center'}>Nenhum microfone encontrado</DialogTitle>}
            <List sx={{ pt: 0 }} dense>
                {microfones
                    ?.reduce((acc, current) => {
                        if (!acc.find((item) => item.deviceId === current.deviceId)) {
                            return acc.concat([current]);
                        } else {
                            return acc;
                        }
                    }, [])
                    .map((mic) => (
                        <ListItem disableGutters key={mic.deviceId}>
                            <ListItemButton onClick={() => onSelected(mic)}>
                                <ListItemIcon>
                                    {selectedValue && selectedValue.deviceId == mic.deviceId ? (
                                        <RadioButtonCheckedIcon />
                                    ) : (
                                        <RadioButtonUncheckedIcon />
                                    )}
                                </ListItemIcon>
                                <ListItemText primary={mic.label} />
                            </ListItemButton>
                        </ListItem>
                    ))}
            </List>
        </Dialog>
    );
}

export default SelectMicDialog;
