export class ErrorMicrofone extends Error {
    constructor(message) {
        super(message);
        this.name = 'ErrorMicrofone';
    }
}

export const SolicitaPermissaoMicrofone = async function () {
    const midiaDispositivos = navigator.mediaDevices;
    if (midiaDispositivos) {
        try {
            const stream = await GetStream();
            stream.getTracks().forEach((track) => track.stop());
            const dispositvos = await midiaDispositivos.enumerateDevices();
            if (dispositvos.length === 0) {
                console.error('Nenhum dispositivo de entrada de áudio encontrado');
                throw new ErrorMicrofone('Nenhum dispositivo de entrada de áudio encontrado');
            }

            const microfones = dispositvos.filter((dispositivo) => dispositivo.kind === 'audioinput');
            if (microfones.length === 0) {
                console.error('Nenhum microfone encontrado');
                throw new ErrorMicrofone('Nenhum microfone encontrado');
            }

            return microfones;
        } catch (err) {
            console.error(err);
            throw new ErrorMicrofone(
                'Permissão negada para acessar o microfone, por favor verifique as configurações do seu navegador, ou entre em contato com suporte.',
            );
        }
    } else {
        console.error('Seu navegador não suporta a API de Dispositivos de Mídia');
        throw new ErrorMicrofone('Seu navegador não suporta a API de Dispositivos de Mídia');
    }
};

export async function GetStream(constraints) {
    if (!constraints) {
        constraints = { audio: true, video: false };
    }
    return await navigator.mediaDevices.getUserMedia(constraints);
}

export async function StopStream(stream) {
    if (!stream) return;
    stream.getTracks().forEach((track) => track.stop());
}

export function microphoneVolumeMedio(analyser) {
    let array = new Uint8Array(analyser.frequencyBinCount);
    analyser.getByteFrequencyData(array);
    let values = 0;

    let length = array.length;
    for (let i = 0; i < length; i++) {
        values += array[i];
    }

    let average = values / length;

    // Considera-se que o microfone está mudo se o valor médio for muito baixo
    return average;
}

export async function gerarArquivoDaGravacao(leftchannel, rightchannel, recordingLength, sampleRate) {
    // console.log('gerando arquivo de gravação');
    function mergeBuffers(channelBuffer, recordingLength) {
        let result = new Float32Array(recordingLength);
        let offset = 0;
        let lng = channelBuffer.length;
        for (let i = 0; i < lng; i++) {
            let buffer = channelBuffer[i];
            result.set(buffer, offset);
            offset += buffer.length;
        }
        return result;
    }

    function interleave(leftChannel, rightChannel) {
        let length = leftChannel.length + rightChannel.length;
        let result = new Float32Array(length);

        let inputIndex = 0;

        for (let index = 0; index < length; ) {
            result[index++] = leftChannel[inputIndex];
            result[index++] = rightChannel[inputIndex];
            inputIndex++;
        }
        return result;
    }

    function writeUTFBytes(view, offset, string) {
        let lng = string.length;
        for (let i = 0; i < lng; i++) {
            view.setUint8(offset + i, string.charCodeAt(i));
        }
    }

    // we flat the left and right channels down
    let leftBuffer = mergeBuffers(leftchannel, recordingLength);
    let rightBuffer = mergeBuffers(rightchannel, recordingLength);

    // we interleave both channels together
    let interleaved = interleave(leftBuffer, rightBuffer);

    // we create our wav file
    let buffer = new ArrayBuffer(44 + interleaved.length * 2);
    let view = new DataView(buffer);

    // RIFF chunk descriptor

    writeUTFBytes(view, 0, 'RIFF');
    view.setUint32(4, 44 + interleaved.length * 2, true);
    writeUTFBytes(view, 8, 'WAVE');
    // FMT sub-chunk
    writeUTFBytes(view, 12, 'fmt ');
    view.setUint32(16, 16, true);
    view.setUint16(20, 1, true);
    // stereo (2 channels)
    view.setUint16(22, 2, true);
    view.setUint32(24, sampleRate, true);
    view.setUint32(28, sampleRate * 4, true);
    view.setUint16(32, 4, true);
    view.setUint16(34, 16, true);
    // data sub-chunk
    writeUTFBytes(view, 36, 'data');
    view.setUint32(40, interleaved.length * 2, true);

    // write the PCM samples
    let lng = interleaved.length;
    let index = 44;
    let volume = 1;
    for (let i = 0; i < lng; i++) {
        view.setInt16(index, interleaved[i] * (0x7fff * volume), true);
        index += 2;
    }

    // our final binary blob
    const blob = new Blob([view], { type: 'audio/mp3' });
    // const urlArquivoAudio = URL.createObjectURL(blob);

    return blob;
}
