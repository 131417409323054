import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    height: 100vh;
    width: 100%;

    > .ContainerBackgrond {
        display: flex;
        flex-direction: column;
        flex: 1;
        height: 100%;
        margin: 10px;
        margin-left: 0px;
        background-color: #fff;
        border-radius: 10px;
        padding: 10px;
        overflow: hidden;
    }
    @media screen and (max-width: 800px) {
        overflow: hidden auto;
    }
`;

export const ContainerInfos = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
`;
